<template>
  <div>
    <v-form>
      <v-dialog v-model="showeventForm" width="80%" scrollable persistent max-width="1000px">
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title>
              {{ "Add Booking" }}
              <v-spacer></v-spacer>
              <div
                md="3"
                v-if="promotions.length > 0"
                style="margin-bottom: -20px"
              >
                <v-autocomplete
                  v-if="bookingForm.card_number == null"
                  :items="[
                    { name: 'None', promotion_code: null },
                    ...promotions,
                  ]"
                  item-text="name"
                  height="50"
                  item-value="promotion_code"
                  v-model="bookingForm.promotion_code"
                  background-color="rgb(206, 168, 0)"
                  outlined
                  @change="verifyBenefit('promotion')"
                  label="Promotions"
                >
                </v-autocomplete>
              </div>
            </v-card-title>
            <div class="d-flex justify-space-around font-bold text-base pa-4 text-neon bg-neon opacity-10">
              <div class="pitch">Event: {{ event_name }}</div>
              <div class="pitch">Service: {{ service }}</div>
              <div class="pitch">
                Price: {{ bookingForm.price | toCurrency }}
                <span
                  v-if="bookingForm.discount != null"
                  class="text-decoration-line-through"
                >
                  {{ bookingForm.discount.actual_price | toCurrency }}
                </span>
              </div>
            </div>

            <v-card-text>
              <v-card
                class="pa-4"
                outlined
              >
                <div class="font-semibold text-base font-16">Event Details</div>
                <v-divider></v-divider>

                <v-card flat class="d-flex">
                  <v-img
                      class="ground_Img"
                      :src="
                          image_path
                            ? s3BucketURL + image_path
                            : require('@/assets/images/default_images/event_default.jpg')
                        "
                      height="180"
                  >
                    <v-row
                        class="fill-height ma-0 hoverImage"
                        align="center"
                        justify="center"
                    >
                      <div
                          align="center"
                          justify="center"
                          class="white--text eventbox pa-5"
                          style="width: 50%"
                      >
                        <div class="pa-0 title">
                          {{ location }}
                        </div>
                        <div class="pa-0 title">
                          {{ date | dateformat }}
                        </div>
                        <div class="pa-0 title">
                          {{ start_time | timeFormat }} to
                          {{ end_time | timeFormat }}
                        </div>
                      </div>
                    </v-row>
                  </v-img>
                </v-card>

<!--                <v-row>-->
<!--                  <v-col-->
<!--                    class-->
<!--                    sm="6"-->
<!--                    v-if="event && event.lineups && event.lineups.length > 0"-->
<!--                  >-->
<!--                    <h4 class="text-center text&#45;&#45;lighten-1 mb-1">Lineup</h4>-->

<!--                    <v-card-->
<!--                      class="pa-2 overflow-y-auto grey&#45;&#45;text text&#45;&#45;lighten-2"-->
<!--                      style="min-height: 190px; max-height: 190px"-->
<!--                      elevation="0"-->
<!--                      tile-->
<!--                      pa-0-->
<!--                    >-->
<!--                      &lt;!&ndash; -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->

<!--                      <v-card-text>-->
<!--                        <v-row no-gutters>-->
<!--                          <v-col md="1">-->
<!--                            <v-btn-->
<!--                              @click="carosal('left')"-->
<!--                              icon-->
<!--                              x-large-->
<!--                              style="margin-top: 50px; margin-left: -18px"-->
<!--                            >-->
<!--                              <v-icon x-large>mdi-chevron-left</v-icon>-->
<!--                            </v-btn>-->
<!--                          </v-col>-->
<!--                          <v-col>-->
<!--                            <v-row style="min-height: 150px; max-height: 150px">-->
<!--                              <v-col md="6" v-if="event.lineups[0] != null">-->
<!--                                <v-card max-width="300" title flat>-->
<!--                                  <div md="12" align="center">-->
<!--                                    <v-avatar size="120" right>-->
<!--                                      <img-->
<!--                                        v-if="-->
<!--                                          event.lineups[0].image_path != null &&-->
<!--                                            event.lineups[0].image_path != '0'-->
<!--                                        "-->
<!--                                        :src="-->
<!--                                          s3BucketURL +-->
<!--                                            event.lineups[0].image_path-->
<!--                                        "-->
<!--                                        :alt="event.lineups[0].name"-->
<!--                                      />-->
<!--                                      <img-->
<!--                                        v-else-->
<!--                                        src="@/assets/images/default_images/image_default.jpg"-->
<!--                                      />-->
<!--                                    </v-avatar>-->
<!--                                  </div>-->
<!--                                  <div class="text-center title">-->
<!--                                    {{ event.lineups[0].name }}-->
<!--                                  </div>-->
<!--                                </v-card>-->
<!--                              </v-col>-->
<!--                              <v-col md="6" v-if="event.lineups[0] != null">-->
<!--                                <v-card-actions class="justify-center">-->
<!--                                  {{ event.lineups[0].description }}-->
<!--                                </v-card-actions>-->
<!--                              </v-col>-->
<!--                            </v-row>-->
<!--                          </v-col>-->
<!--                          <v-col md="1">-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn-->
<!--                              @click="carosal('right')"-->
<!--                              icon-->
<!--                              x-large-->
<!--                              style="margin-top: 50px; margin-left: -5px"-->
<!--                            >-->
<!--                              <v-icon x-large>mdi-chevron-right</v-icon>-->
<!--                            </v-btn>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </v-card-text>-->
<!--                    </v-card>-->
<!--                  </v-col>-->

<!--                  <v-col-->
<!--                    cols="6"-->
<!--                    md="6"-->
<!--                    class="detail_box"-->
<!--                    v-if="event && event.description != 'null'"-->
<!--                  >-->
<!--                    <h4 class="text-center text&#45;&#45;lighten-1 mb-1">-->
<!--                      Description-->
<!--                    </h4>-->
<!--                    <v-card-->
<!--                      class="pa-2 overflow-y-auto text&#45;&#45;lighten-2"-->
<!--                      style="min-height: 190px; max-height: 190px"-->
<!--                      elevation="0"-->
<!--                      tile-->
<!--                      pa-0-->
<!--                      >{{ event.description }}</v-card-->
<!--                    >-->
<!--                  </v-col>-->
<!--                </v-row>-->
              </v-card>

              <div
                class="bordered bg-white pa-4 rounded-lg mt-4"
              >
                <v-row>
                  <v-col sm="10">
                    <div class="font-semibold text-base font-16 text-black">Ticket Details</div>
                    <v-col md="12">
                      <v-chip
                        label
                        color="#00b0af"
                        dark
                        class="ml-2"
                        v-for="(product, index) in bookingForm.products"
                        :key="index"
                        :close="!product.rental && !product.order_item_id"
                        @click:close="removeProduct(index)"
                      >
                        <v-avatar left>
                          <view-image
                            :image="product.image_path"
                            :contain="false"
                          ></view-image>
                        </v-avatar>
                        {{ product.name }} x {{ product.quantity }} -

                        {{ product.price | toCurrency }}
                        <span
                          v-if="product.discount != null"
                          class="text-decoration-line-through pl-1"
                        >
                          {{ product.discount.actual_price | toCurrency }}</span
                        >
                      </v-chip>
                    </v-col>
                  </v-col>
                  <v-col
                    sm="2"
                    v-if="
                      checkReadPermission($modules.salesTeam.management.slug)
                    "
                  >
                    <label for="">
                      Sales Team
                    </label>
                    <v-select
                      v-model="bookingForm.sales_team_id"
                      :items="salesTeams"
                      item-text="name"
                      item-value="id"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      dense
                      class="q-autocomplete shadow-0"
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <label for="">
                      Select Ticket
                    </label>
                    <v-autocomplete
                      v-model="selectedTicket"
                      required
                      return-object
                      :items="this.event.tickets"
                      item-value="event_ticket_id"
                      item-text="name"
                      outlined
                      background-color="#fff"
                      @change="selectTicket"
                      dense
                      class="q-autocomplete shadow-0"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="3">
                    <label for="">
                      Quantity
                    </label>
                    <v-text-field
                      outlined
                      background-color="#fff"
                      type="number"
                      min="1"
                      v-model="selectedTicket.ticketQnt"
                      @keyup="quantityCheck"
                      dense
                      class="q-text-field shadow-0"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <label for="">Price</label>
                    <v-text-field
                      :readonly="productCategoryId != -1"
                      outlined
                      background-color="#fff"
                      v-model="selectedTicket.total_amount"
                      :suffix="currencyCode"
                      dense
                      class="q-text-field shadow-0"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col md="2" align-self="end">
                    <v-btn
                      class="white--text blue-color"
                      block
                      @disabled="
                        this.selectedTicket.quantity >
                          this.selectedTicket.totalQnt
                      "
                      @click="addProduct"
                      >Add</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div
                v-for="(customer, index) in event_customers"
                :key="index"
                class="shadow-0 rounded-lg mt-4 bg-white pa-4 relative"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                      v-if="event_customers.length > 1"
                      @click="deleteCustomer(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <v-row no-gutters>
                  <v-col md="8">
                    <div class="text-black d-flex justify-space-between mt-5">
                      <div>Customer #{{ index + 1 }} Details</div>
                    </div>
                  </v-col>
                  <!-- Uncomment to enable emirates ID reader -->
                  <v-col
                    md="4"
                    class="text-right d-flex flex-row-reverse gap-x-2"
                    v-if="!order_id"
                  >
                    <card-reader-button
                        className="blue-text mt-5"
                        label="Samsotech Reader"
                        docType="chip"

                        @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                    ></card-reader-button>
                    <card-data-button
                        className="blue-text mt-5 ml-4"
                        label="HID Omnikey"
                        @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                    ></card-data-button>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters class="mt-3">
                  <v-col md="3" v-if="index < 1">
                    <v-radio-group
                      v-model="customer.customer_type"
                      class="d-flex"
                      row
                      @change="customerTypeChange(index)"
                      mandatory
                      :readonly="order_id > 0"
                    >
                      <v-radio
                        label="Normal"
                        color="cyan"
                        value="normal"
                      ></v-radio>
                      <!-- <v-radio
                        label="Corporate"
                        color="cyan"
                        value="corporate"
                      ></v-radio> -->
                      <v-radio
                        label="Member"
                        color="cyan"
                        value="member"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col md="2" v-if="customer.customer_type == 'member' && index < 1" >
                    <label for="">Card No*</label>
                    <v-member-search
                      v-model="customer.member"
                      @clear="clearBenefit(index)"
                      :selected="customer.card_number"
                      @updateCustomer="(data) => setMemberData(data,index)"
                      label=""
                    >
                    </v-member-search>
                  </v-col>
                  <!-- <v-col md="3" v-if="customer.customer_type == 'corporate'">
                    <v-autocomplete
                      class="mt-4"
                      label="Company Name"
                      :items="companies"
                      v-model="customer.company_id"
                      item-text="name"
                      item-value="id"
                      outlined
                      background-color="#fff"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="2" v-if="customer.customer_type == 'corporate'">
                    <v-autocomplete
                      :disabled="customer.company_id == null"
                      :items="getCompanySales()"
                      label="Sale Order ID"
                      item-text="sale_seq_no"
                      item-value="id"
                      class="mt-4 ml-2"
                      v-model="customer.company_sale_id"
                      outlined
                      background-color="#fff"
                      dense
                    >
                    </v-autocomplete>
                  </v-col> -->
                  <v-spacer></v-spacer>
                  <v-col md="3">
                    <v-switch
                      style="float: right"
                      v-model="customer.opt_marketing"
                      label="Opt In Marketing"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="4" md="4">
                    <label for="">
                      Mobile No*
                    </label>
                    <v-mobile-search
                      label=""
                      :selected="customer.mobile"
                      :index="index"
                      @updateCustomer="setCustomerData($event, index)"
                      v-model="customer.search"
                      :dense="true"
                      hide-details="auto"
                      class-name1="q-autocomplete shadow-0"
                      class-name2="q-autocomplete shadow-0"
                      :showLabel="false"
                    ></v-mobile-search>
                  </v-col>

                  <v-col sm="4" md="4">
                    <label for="">Name*</label>
                    <v-name-search
                      label=""
                      :selected="customer.name"
                      :mobile="customer.mobile"
                      :email="customer.email"
                      @updateCustomer="setCustomerData($event, index)"
                      v-model="customer.nameSearch"
                      :dense="true"
                      hide-details="auto"
                      class-name="q-autocomplete shadow-0"
                    ></v-name-search>
                  </v-col>

                  <v-col sm="4" md="4">
                    <label for="">
                      Email{{ field.email.is_required ? '*' : '' }}
                    </label>
                    <v-text-field
                      :readonly="customer.customer_id != null"
                      outlined
                      background-color="#fff"
                      v-model="customer.email"
                      :placeholder="
                        `Email${field.email.is_required ? '*' : ''}`
                      "
                      :rules="emailRule"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.gender.is_visible">
                    <label for="">
                      Gender{{ field.gender.is_required ? '*' : '' }}
                    </label>
                    <v-select
                      :items="['Male', 'Female']"
                      :placeholder="
                        `Gender${field.gender.is_required ? '*' : ''}`
                      "
                      outlined
                      v-model="customer.gender"
                      :rules="genderRule"
                      background-color="#fff"
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.dob.is_visible && customerAgeRange">
                    <label for="">
                      Age Group
                    </label>
                    <v-select
                        v-if="customerAgeRange"
                        v-model="customer.age_group"
                        :items="ageRanges"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>

                  <v-col sm="4" md="4" v-if="field.dob.is_visible && !customerAgeRange">
                    <label for="">
                      Date of Birth{{ field.dob.is_required ? '*' : '' }}
                    </label>
                    <date-of-birth
                      :placeholder="
                        `Date of Birth${field.dob.is_required ? '*' : ''}`
                      "
                      label=""
                      :rules="dobRule(customer)"
                      v-model="customer.dob"
                      dense
                      hide-details="auto"
                      class-name="q-text-field shadow-0"
                    >
                    </date-of-birth>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.nationality.is_visible">
                    <label for="">
                      Nationality{{ field.nationality.is_required ? '*' : '' }}
                    </label>
                    <v-autocomplete
                      :items="countries"
                      :hint="
                        `Nationality${field.nationality.is_required ? '*' : ''}`
                      "
                      :rules="nationalityRule"
                      item-value="id"
                      item-text="name"
                      outlined
                      v-model="customer.country_id"
                      background-color="#fff"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.idProof.is_visible">
                    <label for="">
                      ID Type{{ field.idProof.is_required ? '*' : '' }}
                    </label>
                    <v-select
                      :hint="`ID Type${field.idProof.is_required ? '*' : ''}`"
                      :rules="idTypeRule"
                      outlined
                      item-value="id"
                      item-text="name"
                      :items="idProofTypes"
                      v-model="customer.id_proof_type_id"
                      @change="changeIdProofTypeId"
                      background-color="#fff"
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.idProof.is_visible">
                    <v-row no-gutters>
                      <v-col md="7">
                        <label for="">
                          ID Number{{ field.idProof.is_required ? '*' : '' }}
                        </label>
                        <v-text-field
                          :hint="
                            `ID Number${field.idProof.is_required ? '*' : ''}`
                          "
                          :rules="idTypeRule"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          outlined
                          v-model="customer.id_proof_number"
                          background-color="#fff"
                        ></v-text-field>
                      </v-col>
                      <v-col md="5">
                        <label for="">
                          ID Proof{{ field.image.is_required ? '*' : '' }}
                        </label>
                        <v-file-input
                          v-model="customer.id_proof"
                          :rules="idProofRule"
                          prepend-icon=""
                          prepend-inner-icon="mdi-card-account-details"
                          background-color="#fff"
                          outlined
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                        >
                          <template v-slot:prepend-inner>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  color="cyan"
                                  v-if="customer.id_proof_path"
                                  @click="openFile(customer.id_proof_path)"
                                  v-on="on"
                                >
                                  mdi-download-box
                                </v-icon>
                                <v-icon v-else v-on="on">
                                  mdi-card-account-details
                                </v-icon>
                              </template>
                              <span v-if="customer.id_proof_path"
                                >Download uploaded file</span
                              >
                              <span v-else>Upload ID Proof</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                            >
                              <span style="width: 40px" class="text-truncate">{{
                                text
                              }}</span>
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <div style="margin-top: -110px"></div>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.image.is_visible">
                    <v-row no-gutters>
                      <v-col md="8">
                        <label for="">
                          Customer Image{{ field.image.is_required ? '*' : '' }}
                        </label>
                        <v-file-input
                          v-model="customer.image"
                          prepend-icon=""
                          :placeholder="
                            `Image${field.image.is_required ? '*' : ''}`
                          "
                          :rules="imageRule"
                          prepend-inner-icon="mdi-image"
                          background-color="#fff"
                          outlined
                          show-size
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                            >
                              <span
                                style="width: 120px"
                                class="text-truncate"
                                >{{ text }}</span
                              >
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col md="4" align-self="end">
                        <v-btn
                          large
                          block
                          style="background-color: #fff"
                          outlined
                          height="40"
                          color="blue-grey"
                          class="white--text q-text-field shadow-0 bordered"
                          @click="webcamDialog = true"
                        >
                          <v-icon dark>mdi-camera</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.tag.is_visible">
                    <label for="">
                      Tags{{ field.tag.is_required ? '*' : '' }}
                    </label>
                    <v-select
                      :items="tags"
                      outlined
                      item-value="id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="customer.customer_tag"
                      :rules="tagRule"
                      background-color="#fff"
                      multiple
                      return-object
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <div
                class="add_btn pb-4"
                style="margin-top: -30px"
                v-if="displayed_participant < participant_count"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="teal"
                      fab
                      x-small
                      dark
                      @click="addCustomer()"
                    >
                      <v-icon small>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add
                </v-tooltip>
              </div>
            </v-card-text>
            <v-card-actions class="border-top">
              <v-spacer></v-spacer>
              <v-btn
                v-if="bookingForm.status_id == 5"
                @click="$emit('pay', bookingForm.order_id)"
                class="ma-2 white--text teal-color"
                text
              >
                Pay
              </v-btn>
              <v-btn
                v-if="bookingForm.status_id == 5"
                @click="confirmCancel"
                class="ma-2 white--text red-color"
                text
                >Cancel Booking
              </v-btn>
              <v-btn @click="close()" class="ma-2 text--primary bg-transparent text-capitalize" text
                >Close
              </v-btn>
              <v-btn
                class="ma-2 white--text bg-blue text-capitalize"
                @click="confirmBooking"
                text
              >
                Confirm Booking
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <capture-image
      :open="webcamDialog"
      @close="webcamDialog = false"
      @confirm="confirmImageCapture"
    />
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>
<script>
import VMemberSearch from "@/components/Customer/VMemberSearch";
import CaptureImage from "@/components/Image/CaptureImage";
import moment from "moment";
import bookingFields from "@/mixins/bookingFieldValidation";

export default {
  props: {
    showeventForm: { type: Boolean },
    start_time: { type: String },
    end_time: { type: String },
    date: { type: String },
    facility_id: { type: Number },
    order_id: { type: Number },
    id: { type: Number, default: 0 },
    event_name: { type: String },
    venue_service_id: { type: Number },
    service: { type: String },
    event_id: { type: Number },
    location: { type: String },
    event_type_id: { type: Number },
    start_date: { type: String },
    end_date: { type: String },
    image_path: { type: String },
    participants: { type: Number, default: 0 },
    sales: { type: Number, default: 0 },
  },
  components: {
    CaptureImage,
    VMemberSearch,
  },
  mixins: [bookingFields],
  data() {
    return {
      ticketTypes: {},
      event: {},
      participant_count: 1,
      bookingForm: { price: 0, opt_marketing: false },
      event_customers: [],
      selectedTicket: {},
      productCategoryId: null,
      webcamDialog: false,
      endTimes: [],
      categories: [],
      companies: [],
      valid: false,
      repeatData: {
        available: [],
        excluded: [],
      },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      isEmiratesIdCheck: false,
      displayed_participant: 1,
    };
  },
  watch: {
    showeventForm(val) {
      if (val === true) {
        this.bookingForm = { price: 0 };
        this.selectedTicket = {};
        this.getEventDetails();
        this.$store.dispatch("loadPromotions", {
          date: this.date,
          product_type: "Event",
        });
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
        if (!this.$store.getters.getConfigurationStatus(this.venue_service_id)) {
          this.$store.dispatch("loadConfigurationsByVenueServiceId", this.venue_service_id);
        }
      }
    },
    "bookingForm.product"() {
      this.calculateMaxParticipants();
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }

    this.checkPermission = this.checkExportPermission(
      this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Event");
      this.$forceUpdate();
    }

    if (this.$store.getters.getCustomerAgeRangeConfiguration.status == false) {
      this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
    }
    if (this.$store.getters.getCustomerAgeRange.status == false) {
      this.$store.dispatch("LoadCustomerAgeRange");
    }


  },
  computed: {
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
          this.venue_service_id
      );
    },
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
  },
  methods: {
    close() {
      this.event_customers = [];
      this.participant_count = 1;
      this.displayed_participant = 1;
      this.$refs.form.resetValidation();
      this.$emit("close");
    },

    getOrderDetails() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/events/bookings/${this.order_id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            const data = response.data.data;
            // let customers = [];

            this.$set(
              this.bookingForm,
              "name",
              data.customer.first_name +
                `${
                  data.customer.last_name ? " " + data.customer.last_name : ""
                }`
            );
            data.customer.customer_id = data.customer.id;
            // this.setCustomerData(data.customer);

            if (data.customer) {
              this.displayed_participant = 1;
              this.bookingForm.name = `${data.customer.first_name}${
                data.customer.last_name ? " " + data.customer.last_name : ""
              }`;
              this.bookingForm.first_name = data.customer.first_name;
              this.bookingForm.last_name = data.customer.last_name;
              this.bookingForm.mobile = data.customer.customer_contact.mobile;
              this.bookingForm.email = data.customer.customer_contact.email;
              this.bookingForm.gender = data.customer.gender;
              this.bookingForm.dob = data.customer.dob;
              this.bookingForm.age_group = data.customer.age_group;
              this.bookingForm.country_id = data.customer.country_id;
              this.bookingForm.opt_marketing =
                data.customer.opt_marketing == 1 ? true : false;
              if (
                this.bookingForm.customer_type == "member" &&
                !this.bookingForm.card_number
              ) {
                this.searchMember(
                  data.customer.mobile,
                  data.customer.customer_id,
                  data.customer.first_name,
                  data.customer.last_name
                );
              }
            }

            if (data.event_booking) {
              this.bookingForm.id_proof_number =
                data.event_booking.id_proof_number;
              this.bookingForm.id_proof_type_id =
                data.event_booking.id_proof_type_id;
              this.bookingForm.id_proof_number =
                data.event_booking.id_proof_number;
              this.bookingForm.id_proof_file_name =
                data.event_booking.id_proof_file_name;
              this.bookingForm.customer_type = data.event_booking.customer_type;
            }

            if (data.discount != null) {
              if (data.discount.promotion != null) {
                this.bookingForm.promotion_code =
                  data.discount.promotion.promotion_code;
              }
              if (data.discount.member != null) {
                this.bookingForm.card_number = data.discount.member.card_number;
              }
              this.bookingForm.discount = data.discount;
            }
            this.bookingForm.price = data.price;
            this.event_customers.push(this.bookingForm);

            data.event_group_customers.forEach((item) => {
              this.event_customers.push({
                name: `${item.customer.first_name}${
                  item.customer.last_name ? " " + item.customer.last_name : ""
                }`,
                first_name: item.customer.first_name,
                last_name: item.customer.first_name,
                mobile: item.customer.customer_contact.mobile,
                email: item.customer.customer_contact.email,
                gender: item.customer.gender,
                dob: item.customer.dob,
                age_group: item.customer.age_group,
                country_id: item.customer.country_id,
              });
              this.displayed_participant++;
            });

            data.items.forEach((item) => {
              this.bookingForm.products.push({
                order_item_id: item.id,
                price: item.price,
                name: item.name,
                quantity: item.quantity,
                product_id: item.product_id,
                product_type_id: item.product_type_id,
                event_ticket_id: item.event_ticket_id,
                event_id: this.event_id,
              });
            });
            this.calculateMaxParticipants();

            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getEventDetails() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/events/${this.id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.editMode = true;
            let eventData = response.data.data;
            this.event = eventData;
            if (eventData.lineups.length == 0) {
              this.event.lineups = [{}];
            }

            if (eventData.tickets.length == 0) {
              this.event.tickets = [{}];
            }

            this.bookingForm.products = [];
            if (this.order_id) {
              this.getOrderDetails();
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    setCardData(data, index) {
      this.setCustomerData(data, index);
    },
    setCustomerData(data, index = 0) {
      //   console.log(data);
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
        this.searchMember(
          data.mobile,
          data.customer_id,
          data.first_name,
          data.last_name,
          index
        );
      }
      if (!data.customer_id) {
        this.$set(this.event_customers[index], "customer_id", null);
      }
      if (!data.name && data.first_name) {
        this.$set(this.event_customers[index], "name", data.first_name);
      }
      if (
        this.event_customers[index].customer_id &&
        !data.customer_id &&
        this.event_customers[index].name != data.name &&
        this.event_customers[index].mobile != data.mobile
      ) {
        this.$set(this.event_customers[index], "mobile", null);
        this.event_customers[index].search = null;
        this.event_customers[index].nameSearch = null;
        this.$set(this.event_customers[index], "email", null);
        this.$set(this.event_customers[index], "gender", null);
        this.$set(this.event_customers[index], "name", null);
        this.$set(this.event_customers[index], "customer_id", null);
        this.$set(this.event_customers[index], "first_name", null);
        this.$set(this.event_customers[index], "image_path", null);
        this.$set(this.event_customers[index], "dob", null);
        this.$set(this.event_customers[index], "age_group", null);
        this.$set(this.event_customers[index], "country_id", null);
        this.$set(this.event_customers[index], "last_name", null);
        this.$set(this.event_customers[index], "opt_marketing", false);
        this.$set(this.event_customers[index], "id_proof_type_id", null);
        this.$set(this.event_customers[index], "id_proof_number", null);
        this.$set(this.bookingForm, "customer_tag", null);
        this.$set(this.event_customers[index], "id_proof_path", null);
        this.$forceUpdate();
      }
      if (data.mobile)
        this.$set(this.event_customers[index], "mobile", data.mobile);
      if (data.email)
        this.$set(this.event_customers[index], "email", data.email);
      if (data.country_id) {
        this.$set(this.event_customers[index], "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.event_customers[index], "country_id", null);
        }
      }
      if (data.customer_tag) {
        // console.log(data.customer_tag);
        this.$set(
          this.event_customers[index],
          "customer_tag",
          data.customer_tag
        );
      } else {
        this.$set(this.event_customers[index], "customer_tag", null);
      }
      if (data.gender) {
        this.$set(this.event_customers[index], "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.event_customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.event_customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.event_customers[index], "dob", null);
        }
      }
      // console.log('data');
      // console.log(data);
      if (data.age_group) {
        this.$set(this.event_customers[index], "age_group", data.age_group);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.event_customers[index], "age_group", null);
        }
      }
      if (data.name) this.$set(this.event_customers[index], "name", data.name);
      if (data.last_name) {
        this.$set(this.event_customers[index], "last_name", data.last_name);
      } else {
        this.$set(this.event_customers[index], "last_name", null);
      }
      if (data.first_name)
        this.$set(this.event_customers[index], "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.event_customers[index], "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.event_customers[index], "image_path", data.image_path);
      } else {
        this.$set(this.event_customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
          this.event_customers[index],
          "id_proof_type_id",
          data.id_proof_type_id
        );
      }
      if (data.id_proof_path) {
        this.$set(
          this.event_customers[index],
          "id_proof_path",
          data.id_proof_path
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.event_customers[index], "id_proof_path", null);
        }
      }
      if (data.id_proof) {
        this.$set(this.event_customers[index], "id_proof", data.id_proof);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.event_customers[index], "id_proof", null);
        }
      }
      if (data.id_proof_number) {
        this.$set(
          this.event_customers[index],
          "id_proof_number",
          data.id_proof_number
        );
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.event_customers[index], "opt_marketing", true);
        } else {
          this.$set(this.event_customers[index], "opt_marketing", false);
        }
      }
      if (data.customer_documents) {
        this.event_customers[index].customer_documents =
          data.customer_documents;
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_type_id
        ) {
          this.$set(
            this.event_customers[index],
            "id_proof_type_id",
            data.customer_documents[0].id_proof_type_id
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_number
        ) {
          this.$set(
            this.event_customers[index],
            "id_proof_number",
            data.customer_documents[0].id_proof_number
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_path
        ) {
          this.$set(
            this.event_customers[index],
            "id_proof_path",
            data.customer_documents[0].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.event_customers[index].customer_documents = [];
        }
      }
      this.$forceUpdate();
    },
    setMemberData(data,index = null) {
      this.bookingForm.customer_type = "member";
      this.setCustomerData(data,index);
      if (index === null) {
        this.$set(this.bookingForm, "card_number", data.card_number);
        this.$set(this.bookingForm, "membership_id", data.membership_id);
      } else {
        this.$set(
          this.event_customers[index],
          "card_number",
          data.card_number
        );
        this.$set(
          this.event_customers[index],
          "membership_id",
          data.membership_id
        );
      }
      this.verifyBenefit("membership");
    },
    setCustomerName(data) {
      this.$set(this.bookingForm, "last_name", data.last_name);
      this.$set(this.bookingForm, "first_name", data.first_name);
    },
    confirmImageCapture(image) {
      image.name = this.bookingForm.name
        ? this.bookingForm.name + "_" + moment().format("YYYYMMDDHHSS")
        : "user_image_" + moment().format("YYYYMMDDHHSS");
      this.bookingForm.image = image;
      this.webcamDialog = false;
    },

    selectTicket() {
      if (
        this.selectedTicket.quantity == 0 ||
        this.selectedTicket.quantity == this.selectedTicket.participants ||
        this.selectedTicket.quantity < this.selectedTicket.participants
      ) {
        this.showError("Tickets not available");
        this.selectedTicket = {};
        return;
      }
      this.selectedTicket.ticketQnt = 1;
      this.$forceUpdate();

      // let currentTicket = this.event.tickets.findIndex(
      //   (x) => x.product_id == this.selectedTicket.product_id
      // );
      // console.log(this.event.tickets[currentTicket]);
      // this.participant_count = this.event.tickets[currentTicket].participant_count;
    },

    quantityCheck() {
      if (
        this.selectedTicket.ticketQnt >
        this.selectedTicket.quantity - this.selectedTicket.participants
      ) {
        this.showError("Quantity larger than available tickets");
      }
    },

    carosal(direction) {
      if (direction == "left")
        this.event.lineups.unshift(this.event.lineups.pop());
      else this.event.lineups.push(this.event.lineups.shift());
    },

    calculateMaxParticipants() {
      let old = this.participant_count;
      let max = this.bookingForm.products.reduce(
        (acc, num) =>
          acc +
          parseInt(num.quantity) *
            this.findTicketParticipantCount(num.product_id),
        0
      );
      // console.log("max");
      // console.log(max);
      if (old > max && max < this.displayed_participant) {
        this.displayed_participant = max;
      }
      // console.log("this.displayed_participant");
      // console.log(this.displayed_participant);
      this.participant_count = max;
      // console.log("this.participant_count");
      // console.log(this.participant_count);
    },
    findTicketParticipantCount(productId) {
      let currentTicket = this.event.tickets.findIndex(
        (x) => x.product_id == productId
      );
      // console.log("each");
      // console.log(this.event.tickets[currentTicket].participant_count);
      return parseInt(this.event.tickets[currentTicket].participant_count ?? 0);
    },
    refreshCustomers() {
      this.event_customers.splice(this.participant_count);
      //   this.isEmiratesIdCheck.splice(this.participant_count);
    },
    addProduct() {
      if (
        this.selectedTicket.ticketQnt >
        this.selectedTicket.quantity - this.selectedTicket.participants
      ) {
        this.showError("Quantity larger than available tickets");
        return;
      }
      let quantity = this.selectedTicket.ticketQnt
        ? parseInt(this.selectedTicket.ticketQnt)
        : 1;

      if (this.selectedTicket.event_ticket_id == null) {
        this.showError("Please add product");
        return;
      }
      // console.log(this.selectedTicket,'selected ticket')
      let price = this.selectedTicket.total_amount * quantity;
      if (
        this.bookingForm.products.length > 0 &&
        this.bookingForm.products.find(
          (x) => x.product_id == this.selectedTicket.product_id
        )
      ) {
        let index = this.bookingForm.products.findIndex(
          (x) => x.product_id == this.selectedTicket.product_id
        );

        this.bookingForm.products[index].quantity += parseInt(quantity);
        this.bookingForm.products[index].price += price;
      } else {
        // let currentTicket = this.event.tickets.findIndex(
        //   (x) => x.product_id == this.selectedTicket.product_id
        // );
        // this.participant_count =
        //   this.event.tickets[currentTicket].participant_count * parseInt(quantity);
        // console.log(this.event_customers.length);
        // if (this.event_customers.length > 0) {
        //   this.event_customers = this.event_customers.splice(1);
        // } else {
        //   this.event_customers = [];
        // }

        //check if participant count is less than current participants, then remove that many participants
        // console.log(this.event_customers.length);
        // // else {
        if (this.event_customers.length == 0) {
          this.event_customers.push({
            customer_type: null,
            member: null,
            company_id: null,
            company_sale_id: null,
            opt_marketing: null,
            mobile: null,
            name: null,
            email: null,
            nameSearch: null,
            customer_id: null,
            gender: null,
            country_id: null,
            id_proof_type_id: null,
            id_proof_number: null,
            id_proof: null,
            id_proof_path: null,
            image: null,
          });
        }
        // this.isEmiratesIdCheck = [];
        // this.isEmiratesIdCheck.push(false);
        // }

        this.bookingForm.products.push({
          price: price,
          event_id: this.event_id,
          name: this.selectedTicket.name,
          quantity: quantity,
          product_id: this.selectedTicket.product_id,
          event_ticket_id: this.selectedTicket.event_ticket_id,
          product_type_id: this.selectedTicket.product_type_id,
          tax: this.selectedTicket.tax_amount,
        });
      }

      let ticketIndex = this.event.tickets.findIndex(
        (x) => x.product_id == this.selectedTicket.product_id
      );

      this.event.tickets[ticketIndex].participants += parseInt(quantity);

      this.bookingForm.price += price;
      this.selectedTicket = {};
      if (this.bookingForm.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.bookingForm.card_number != null) {
        this.verifyBenefit("membership");
      }
      this.calculateMaxParticipants();
      //   this.refreshCustomers();
      this.$forceUpdate();
    },
    addCustomer() {
      if (this.displayed_participant < this.participant_count) {
        this.event_customers.push({
          customer_type: "normal",
          member: null,
          company_id: null,
          company_sale_id: null,
          opt_marketing: null,
          mobile: null,
          name: null,
          email: null,
          nameSearch: null,
          customer_id: null,
          gender: null,
          country_id: null,
          id_proof_type_id: null,
          id_proof_number: null,
          id_proof: null,
          id_proof_path: null,
          image: null,
        });
        this.displayed_participant++;
        this.$forceUpdate();
        if (this.venueServiceConfiguration.auto_fill_customers) {
          this.autoFillCustomer();
        }
      }

      //   this.isEmiratesIdCheck.push(false);
    },
    autoFillCustomer() {
      if (!this.event_customers) {
        return;
      }
      let index =
          this.event_customers.length > 0
              ? this.event_customers.length - 1
              : null;
      if (index >= 0) {
        let data = {
          customer_id: null,
          name: this.event_customers[0].name + " Guest #" + (index),
          first_name: this.event_customers[0].name,
          last_name:" Guest #" + (index),
          mobile: this.event_customers[0].mobile,
          email: this.event_customers[0].email,
        };
        this.setCustomerData(data, index);
      }
    },


    removeProduct(index) {
      let data = this.bookingForm.products[index];
      this.bookingForm.products.splice(index, 1);
      this.bookingForm.price -= data.price;
      if (this.bookingForm.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.bookingForm.card_number != null) {
        this.verifyBenefit("membership");
      }
      if (this.bookingForm.products.length == 0) {
        this.clearBenefit();
        this.bookingForm.promotion_code = null;
        this.bookingForm.price = 0;
      }
      //   this.event_customers = [];
      //   this.participant_count = 1;
      //   this.displayed_participant = 1;
      this.calculateMaxParticipants();
      this.refreshCustomers();
      this.$forceUpdate();
    },
    verifyBenefit(type) {
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.bookingForm.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      } else {
        if (this.bookingForm.card_number) {
          data.card_number = this.bookingForm.card_number;
        } else if (
          this.event_customers[0] &&
          this.event_customers[0].card_number
        ) {
          data.card_number = this.event_customers[0].card_number;
        }
      }
      if (this.bookingForm.mobile) {
        data.mobile = this.bookingForm.mobile;
      }
      if (this.bookingForm.discount) {
        data.products = [];
        this.bookingForm.products.forEach((product) => {
          let pdata = product;
          if (product.discount) {
            pdata.price = product.discount.actual_price;
            delete pdata.discount;
          }
          pdata.price = pdata.product_price;
          data.products.push(pdata);
        });
      } else {
        data.products = this.bookingForm.products;
        data.products.forEach((element) => {
          if (element) {
            element.price = element.price / element.quantity;
          }
        });
      }
      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.bookingForm.discount = data.discount;
            this.bookingForm.price = data.price;
            this.bookingForm.products = data.products;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearBenefit() {
      if (this.bookingForm.discount) {
        this.bookingForm.price = this.bookingForm.discount.actual_price;
        this.bookingForm.products.forEach((product, index) => {
          if (product.discount) {
            this.bookingForm.products[index].price =
              product.discount.actual_price;
          }
          this.bookingForm.products[index].discount = null;
        });
        setTimeout(() => {
          this.bookingForm.discount = null;
          this.$forceUpdate();
        });
      }
    },
    customerTypeChange() {
      if (this.bookingForm.customer_type == "corporate") {
        this.bookingForm.promotion_code = null;
        this.clearBenefit();
        this.getActiveCompanySales();
      }
      if (this.bookingForm.promotion_code == null) {
        this.clearBenefit();
      }
      if (this.bookingForm.customer_type == "normal") {
        this.bookingForm.promotion_code = null;
        this.$set(this.bookingForm, "card_number", null);
        this.clearBenefit();
      }
      if (this.bookingForm.customer_type == "member") {
        this.bookingForm.promotion_code = null;
        this.clearBenefit();
      }
    },
    getActiveCompanySales() {
      this.$http
        .get(`venues/companies/active/sales`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.companies = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCompanySales() {
      return this.bookingForm.company_id != null && this.companies.length
        ? this.companies.find((item) => item.id == this.bookingForm.company_id)
            .company_sale
        : [];
    },

    confirmSinglePayment() {
      let booking = this.bookingForm.repeat_dates.find(
        (item) => item.date == this.date
      );
      this.confirmModel = {
        id: booking.id,
        title: `Pay only for ${moment(booking.date).format("Do MMM YYYY")}?`,
        description: `Do you want to seperately invoice for ${moment(
          booking.date
        ).format(
          "Do MMM YYYY"
        )} from other repeat dates? By clicking <b>Yes</b> you can confirm creating new invoice`,
        type: "singleInvoice",
      };
    },
    cancelSingleDate() {
      let booking = this.bookingForm.repeat_dates.find(
        (item) => item.date == this.date
      );
      this.confirmModel = {
        id: booking.id,
        title: `Cancel ${moment(booking.date).format("Do MMM YYYY")} booking?`,
        description: `Do you want to cancel booking for ${moment(
          booking.date
        ).format(
          "Do MMM YYYY"
        )} from repeated dates? By clicking <b>Yes</b> you can confirm cancelation`,
        type: "singleCancel",
      };
    },
    confirmActions(data) {
      if (data.type == "customer") {
        this.event_customers.splice(data.id, 1);
        this.displayed_participant--;
        // this.isEmiratesIdCheck.splice(data.id, 1);
      }
      this.confirmModel.id = null;
      this.$forceUpdate();
    },
    deleteCustomer(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this customer?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
      this.$forceUpdate();
    },

    confirmBooking() {
      if (!this.$refs.form.validate()) {
        this.showError("Fill all required fields");
        return;
      }
      var formData = new FormData();
      for (let key in this.bookingForm) {
        if (
          this.bookingForm[key] != null &&
          ![
            "id",
            "products",
            "search",
            "member",
            "discount",
            "customer_type",
            "card_number",
          ].includes(key)
        ) {
          if (key == "first_name" || key == "last_name") {
            if (!this.bookingForm["first_name"]) {
              formData.append(`first_name`, this.bookingForm["last_name"]);
            } else {
              formData.append(`first_name`, this.bookingForm["first_name"]);
              if (
                this.bookingForm["last_name"] &&
                this.bookingForm["last_name"] != null
              ) {
                formData.append(`last_name`, this.bookingForm["last_name"]);
              }
            }
          } else {
            formData.append(`${key}`, this.bookingForm[key]);
          }
        }
      }
      //   for ( let index in this.event_customers) {
      //     for(let key in index){
      //         if (key == "first_name" || key == "last_name") {
      //             if (!index["first_name"]) {
      //                 formData.append(`first_name`, index["last_name"]);
      //         } else {
      //           formData.append(`first_name`, index["first_name"]);
      //           if (
      //             index["last_name"] &&
      //             index["last_name"] != null
      //           ) {
      //             formData.append(`last_name`, index["last_name"]);
      //           }
      //         }
      //       } else {
      //           formData.append(`${key}`, index[key]);
      //       }
      //           }
      //   }

      this.event_customers.forEach((element, index) => {
        if (index == 0) {
          if (element["card_number"]) {
            formData.append(`card_number`, element["card_number"]);
          }
        }
        for (let key in element) {
          if (
            key != "discount" &&
            key != "customer_tag" &&
            element[key] != null
          ) {
            formData.append(`customer[${index}][${key}]`, element[key]);
          } else if (key == "customer_tag" && element[key] != null) {
            element[key].forEach((tag, tagKey) => {
              formData.append(
                `customer[${index}][${key}][${tagKey}][id]`,
                tag.id
              );
              formData.append(
                `customer[${index}][${key}][${tagKey}][name]`,
                tag.name
              );
            });
          }
        }
      });

      this.bookingForm.products.forEach((element, index) => {
        for (let key in element) {
          if (key != "discount") {
            formData.append(`products[${index}][${key}]`, element[key]);
          }
        }
      });

      formData.append("event_id", this.event.id);
      formData.append("date", this.date);
      if (this.bookingForm.sales_team_id)
        formData.append("sales_team_id", this.bookingForm.sales_team_id);
      this.$http
        .post(
          `venues/events/booking${this.order_id ? "/" + this.order_id : ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess(
              this.order_id
                ? "Booking successfully updated"
                : "Booking successfully added"
            );
            let data = response.data.data;
            this.event_customers = [];
            this.participant_count = 1;
            this.displayed_participant = 1;
            this.$emit("booked", data.id);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    timeFormat(time) {
      return moment(time, "HH:mm:ss").format("hh:mm a");
    },

    confirmCancel() {
      this.confirmModel = {
        id: this.order_id,
        title: `Do you want cancel this booking?`,
        description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
      };
    },

    searchMember(mobile, id, first_name, last_name, index = 0) {
      console.log(id, first_name, last_name, mobile);
      this.isSearchLoading = true;
      let query = "";
      if (typeof id != "undefined" && id != null) {
        query = `field=id&search=${id}`;
      } else {
        if (typeof mobile != "undefined") {
          query = `field=mobile_number&search=${mobile}`;
        }
      }
      if (query != "") {
        this.$http
          .get(`venues/memberships/members/filters?${query}`)
          .then((response) => {
            if (response.status == 200) {
              let data = response.data.data;
              if (data.length > 0) {
                if (data[0].card_number) {
                  this.event_customers[index].customer_type = "member";
                  this.$set(
                    this.event_customers[index],
                    "card_number",
                    data[0].card_number
                  );
                  this.$forceUpdate();
                  if (
                    this.bookingForm &&
                    this.bookingForm.products.length > 0
                  ) {
                    this.verifyBenefit("membership");
                  }
                }
              } else {
                this.bookingForm.member = null;
                this.event_customers[index].customer_type =
                  this.event_customers[index].customer_type == "corporate"
                    ? "corporate"
                    : null;
                this.$set(this.bookingForm, "card_number", null);
                if (this.bookingForm.promotion_code == null) {
                  this.clearBenefit();
                }
              }
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    changeIdProofTypeId() {
      if (
        this.bookingForm.customer_documents &&
        this.bookingForm.customer_documents.length
      ) {
        let objType = this.bookingForm.customer_documents.find((x) => {
          return x.id_proof_type_id === this.bookingForm.id_proof_type_id;
        });
        if (typeof objType !== "undefined" && objType.id_proof_type_id) {
          this.bookingForm.id_proof_number = objType.id_proof_number;
          this.bookingForm.id_proof_path = objType.id_proof_path;
        } else {
          this.bookingForm.id_proof_number = null;
          this.bookingForm.id_proof_path = null;
        }
      } else {
        // console.log("document length 0");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$card-outlined-border-width: 3px;
</style>
