<template>
  <div>
    <v-dialog
        v-model="userModel"
        max-width="1000"
        persistent
        @input="$emit('close')"
    >

      <v-card>
        <v-card-actions >
          <SvgIcon class="text-2xl font-semibold" style="max-width: 300px;overflow-x:auto" :text="`${customerDetails.first_name || '' } ${ customerDetails.last_name || ''}` " >
          </SvgIcon>
          <v-spacer></v-spacer>
          <v-btn
              v-if="isPermissionCheck"
              :style="{'background-color': customerDetails.status_id == 2 ? 'rgba(86, 92, 104, 0.1)' :'rgba(229, 0, 0, 0.1)'}"
              class="ma-2 red--text text_capitalize "
              text
              @click="deactivateConfirmModal()"
          > <DeactivateBtnIcon/>
            {{
              customerDetails.status_id == 2 ? "Activate" : "Deactivate"
            }} </v-btn>

          <v-btn
              style="background-color: rgba(17, 42, 70, 0.1)" v-if="isPermissionCheck"
              class="ma-2  text_capitalize "
              text
              @click="showEdit()"
          > <EditBtnIcon/>
            <span class="ml-1">Edit</span>   </v-btn>





        </v-card-actions>
        <v-card-text>
          <v-container class="pl-0 pr-0 pt-0 mt-0">
            <v-row dense>
              <div class="col-md-4 pr-0 mr-0">
                <view-image
                    :image="customerDetails.profile_image"
                    :defaultImage="'user'"
                    :height="250"
                    style="border-radius: 6px; max-width: 95%; max-height: 250px;"
                    class="img-fluid dynamic-image"
                ></view-image>
              </div>
              <div class="col-md-8 ml-0 pl-0">
                  <table class="table blue-text" style="border-spacing: 12px">
                    <tr >
                      <td>Name <span style="display: block;max-width: 220px;" class="font-semibold blue-text" >{{ customerDetails.first_name || "" }} {{ customerDetails.last_name || "" }}</span></td>
                      <td>Mobile <span style="display: block" class="font-semibold blue-text">{{ customerDetails.mobile || "--" }}</span></td>
                      <td>Email <span style="display: block" class="font-semibold blue-text"> {{ customerDetails.email || "--" }}</span></td>
                    </tr>

                    <tr>
                      <td>Date Of Birth <span style="display: block" class="font-semibold blue-text">{{ customerDetails.dob || '--' }}  <span v-if="customerDetails.age ">({{ customerDetails.age }})</span>   </span></td>
                      <td>Nationality <span style="display: block" class="font-semibold blue-text">{{ customerDetails.nationality || "--" }} </span></td>
                      <td>Gender <span style="display: block" class="font-semibold blue-text">{{ customerDetails.gender || "--" }}</span></td>
                    </tr>
                    <tr>
                      <td>Weight <span style="display: block" class="font-semibold blue-text">{{ customerDetails.weight || "--" }}</span></td>
                      <td>Height <span style="display: block" class="font-semibold blue-text">{{ customerDetails.height || "--" }}</span></td>
                      <td>Joining Date <span style="display: block" class="font-semibold blue-text"> {{ customerDetails.join_date | dayFormat }}</span></td>
                    </tr>
                    <tr v-if="customerDetails.venue_customer_tags && customerDetails.venue_customer_tags.length">
                      <td colspan="3">Tags <div style="display: block">
                        <v-btn x-small
                               class="mr-2"
                               v-for="(customerTag,
                            index) in customerDetails.venue_customer_tags"
                               :key="index"
                               :color="customerTag.tag.color_code"   >
                          {{ customerTag.tag.name }}
                        </v-btn>

                      </div>   </td>
                    </tr>
                  </table>
              </div>
            </v-row>
            <v-row class="text-center mt-6" align="center" dense>
              <div :class="`col-md-${walletColumn}` " v-if="checkReadPermission($modules.clients.wallet_management.slug)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on"
                         v-bind="attrs" class="d-flex justify-space-between gap-x-4 p-4 align-center" style="border: 1px solid rgba(220, 220, 220, 1); height: 40px ; border-radius: 6px; cursor: pointer" @click="manageCashWallet()">
                      <div class="d-flex align-center">
                        <WalletIcon class=""/>
                        <span class="ml-2 font-bold blue-text">Cash Wallet</span>
                      </div>
                      <span class="font-bold blue-text">  {{ customerDetails.wallet | toCurrency }}</span>
                    </div>
                  </template>
                  <span>Click to manage</span>
                </v-tooltip>
              </div>

              <div :class="`col-md-${walletColumn}`" v-else >
                <div class="d-flex justify-space-between gap-x-4 p-4 align-center" style="border: 1px solid rgba(220, 220, 220, 1); height: 40px ; border-radius: 6px;">
                  <div class="d-flex align-center">
                    <WalletIcon class=""/>
                    <span class="ml-2 font-bold blue-text">Cash Wallet</span>
                  </div>
                  <span class="font-bold blue-text">  {{ customerDetails.wallet | toCurrency }}</span>
                </div>
              </div>


              <div :class="`col-md-${walletColumn}`" >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div   v-on="on"
                           v-bind="attrs" class="d-flex justify-space-between gap-x-4 p-4 align-center" style="border: 1px solid rgba(220, 220, 220, 1); height: 40px ; border-radius: 6px ; cursor: pointer"  @click="getProductWallet">

                      <div class="d-flex align-center"   >
                        <ProductWalletIcon class=""/>
                        <span class="ml-2 font-bold blue-text">Product Wallet</span>
                      </div>
                      <span class="font-bold blue-text">
                          {{ customerDetails.products_wallet_count }}
                  </span>

                    </div>
                  </template>
                  <span>Click to manage</span>
                </v-tooltip>
              </div>


              <div :class="`col-md-${walletColumn}`">
                <div class="d-flex justify-space-between gap-x-4 p-4 align-center" style="border: 1px solid rgba(220, 220, 220, 1); height: 40px ; border-radius: 6px ; ">
                  <div class="d-flex align-center">
                    <CreditWalletIcon class=""/>
                    <span class="ml-2 font-bold blue-text">Credit Owed</span>
                  </div>
                  <span class="font-bold blue-text"> {{ customerDetails.credit_owed | toCurrency }}</span>
                </div>
              </div>
              <div :class="`col-md-${walletColumn}`" v-if="customerDetails.points > 0 && checkReadPermission($modules.qpoints.management.slug)">
                 <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on"
                          v-bind="attrs" class="d-flex justify-space-between gap-x-4 p-4 align-center" style="border: 1px solid rgba(220, 220, 220, 1); height: 40px ; border-radius: 6px; cursor: pointer" @click="manageQPointWallet()">
                        <div class="d-flex align-center">
                          <WalletIcon class=""/>
                          <span class="ml-2 font-bold blue-text">QPoints</span>
                        </div>
                        <span class="font-bold blue-text">  {{ customerDetails.points }}</span>
                      </div>
                    </template>
                    <span>Click to manage</span>
                  </v-tooltip>
              </div>
            </v-row>
            <v-row>
              <div class="col-md-12">
                  <div class="border-bottom">
                    <p style="line-height: 1px" class="blue-text">General Note  <v-icon small v-if="isPermissionCheck" @click="editCustomerField('general_note')" > mdi-pencil </v-icon>  </p>
                    <p class=" font-normal font-bold blue-text"  v-if="!this.editGeneralNote">{{customerNote}}</p>
                    <v-text-field
                        v-model="customerNote"
                        append-icon="mdi-check"
                        clear-icon="mdi-close-circle"
                        clearable
                        type="text"
                        elevation="0"
                        @click:append="updateNote"
                        @click:clear="editCustomerField('general_note')"
                        v-if="this.editGeneralNote"
                    ></v-text-field>

                  </div>
              </div>
              <div class="col-md-12">
                <div class="border-bottom">
                    <p style="line-height: 1px" class="blue-text">Customer Alert  <v-icon small v-if="isPermissionCheck"  @click="editCustomerField('customer_alert')" > mdi-pencil </v-icon>  </p>
                    <p class="font-bold blue-text" v-if="!this.editCustomerAlert">{{customerAlertNote}}</p>

                  <v-text-field
                      v-model="customerAlertNote"
                      append-icon="mdi-check"
                      clear-icon="mdi-close-circle"
                      clearable
                      type="text"
                      elevation="0"
                      @click:append="updateNote"
                      @click:clear="editCustomerField('customer_alert')"

                      v-if="this.editCustomerAlert"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12">
                <div class="">
                  <p style="line-height: 1px" class="blue-text">Delivery Address  <v-icon small v-if="isPermissionCheck" @click="editCustomerField('delivery_address')"> mdi-pencil </v-icon>  </p>
                  <p class="font-bold blue-text"  v-if="!this.editDeliveryAddress">{{customerDeliveryAddress}}</p>
                  <v-text-field
                      v-model="customerDeliveryAddress"
                      append-icon="mdi-check"
                      clear-icon="mdi-close-circle"
                      clearable
                      type="text"
                      elevation="0"
                      @click:append="updateNote"
                      @click:clear="editCustomerField('delivery_address')"
                      v-if="this.editDeliveryAddress"
                  ></v-text-field>
                </div>
              </div>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions class="" style="border-top: 1px solid rgba(0, 0, 0, 0.1)">

          <v-btn
              color=" "
              class="ma-2  "
              text
              @click="closeAll"
          > Close </v-btn>
          <v-spacer></v-spacer>
<!--          <v-btn-->
<!--              v-if="customerAlertNote != null && customerAlertNote != ''"-->
<!--              color=" darken-1"-->
<!--              class="ma-2 white&#45;&#45;text red-color"-->
<!--              text-->
<!--              @click="clearAlertNote()"-->
<!--          >Clear alert</v-btn-->
<!--          >-->
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="getScheduleList('schedule_history')"
          >Schedules</v-btn
          >

          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="
                customerHistory();
              "
          >History</v-btn
          >
          <v-btn   v-if="customerDetails.memberships.length"
                   color=" darken-1"
                   class="ma-2 white--text blue-color"
                   text
                   @click="membershipDialoge = true"

          >Membership</v-btn
          >

          <v-btn   v-if="customerDetails.trainers.length"
                   color=" darken-1"
                   class="ma-2 white--text blue-color"
                   text
                   @click="ptDialoge = true"

          >Personal Training</v-btn
          >
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="getDocuments"
          >Documents</v-btn
          >
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="openNotes(customerDetails)"
          >Notes</v-btn
          >

          <v-btn   v-if="customerDetails.memberships.credits"
                   color=" darken-1"
                   class="ma-2 white--text blue-color"
                   text
                   @click="getCreditList"

          >Credits</v-btn>

<!--          <v-btn-->
<!--              color=" darken-1"-->
<!--              class="ma-2 white&#45;&#45;text teal-color"-->
<!--              text-->
<!--              @click="updateNote"-->
<!--          >Update</v-btn-->
<!--          >-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="membershipDialoge" width="400">
      <v-card>
        <v-card-text v-if="activeMembership.length > 0">
          <v-list v-for="(membership, index) in activeMembership" :key="index">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-wallet-membership</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
              >Membership</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{
                  typeof membership.membership_name != "undefined" &&
                  membership.membership_name != null
                      ? membership.membership_name
                      : "NA"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-package</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
              >Package</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{
                  typeof membership.package_name != "undefined" &&
                  membership.package_name != null
                      ? membership.package_name
                      : "NA"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <div class="d-flex justify-center mt-4">
            <v-btn
                @click="
                historyType = 2;
                customerHistory();
              "
                small
                color="#066a8c"
                dark
            >History</v-btn
            >
          </div>
        </v-card-text>
        <v-card-text v-else class="pa-8 text-center">
          <h3>No memberships found!</h3>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ptDialoge" width="400">
      <v-card>
        <v-card-text
            v-if="customerDetails.trainers && customerDetails.trainers.length > 0"
        >
          <v-list
              v-for="(trainer, index) in customerDetails.trainers"
              :key="index"
          >
            <v-list-item>
              <v-list-item-icon>
                <div class="personal-trainer_ic"></div>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
              >Personal Trainer</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.trainer_name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
              >Training Package</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.package_name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-wallet</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-left"
              >Remaining Sessions</v-list-item-subtitle
              >
              <v-list-item-title class="font-weight-black text-left">
                {{ trainer.remaining_sessions }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <div class="d-flex justify-center mt-4">
            <v-btn
                @click="
                historyType = 5;
                customerHistory();
              "
                small
                color="#066a8c"
                dark
            >History</v-btn
            >
          </div>
        </v-card-text>
        <v-card-text v-else class="pa-8 text-center">
          <h3>No personal trainer found!</h3>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="documentDialoge" width="700" persistent scrollable>
      <v-card>

        <v-card-text>
          <div class="row pt-4">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Documents" >
                </SvgIcon>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive" v-if="documentData && documentData.length">
                <table class="history_table table border-collapse">
                  <thead>
                    <tr class="opacity-70 tr-neon tr-rounded">
                      <th>Name</th>
                      <th>Type</th>
                      <th>File</th>
                    </tr>
                  </thead>
                 <tbody>
                 <tr
                     v-for="(data, index) in documentData"
                     :key="index"
                     class="text-center"
                 >
                   <td>{{ data.name }}</td>
                   <td>
                     {{ data.type }}
                   </td>

                   <td>
                     <v-btn
                         class="mt-2"
                         small
                         block
                         color="white"
                         style="color:black; height:22px;"
                         dark
                         @click="openFile(data.link)"
                     >
                       Download
                       <v-icon right dark>
                         mdi-download
                       </v-icon>
                     </v-btn>
                   </td>
                 </tr>
                 </tbody>
                </table>
              </div>
              <div class="pa-8 text-center" v-else >
                <h3 >No Documents found!</h3>
              </div>
            </div>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="
              () => {
                documentDialoge = false;
              }
            "
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="historyDialoge"
        width="900"
        persistent
        scrollable
        @input="$emit('close')"
    >
      <v-card >
        <v-card-text>
          <div class="d-flex justify-space-between align-center mt-2">
            <SvgIcon class="text-2xl font-semibold" text="Customer History">
            </SvgIcon>
            <v-spacer/>
            <v-autocomplete
                v-model="historyType"
                :items="[{ id: 0, name: 'All' }, ...productTypes]"
                class="q-autocomplete shadow-0 m-r-3 mt-2"
                hide-details
                item-value="id"
                item-text="name"
                @change="customerHistory()"
                outlined
                dense
                placeholder="Select Type"
                style="max-width: 200px"
            />
          </div>
          <div class="table-responsive mt-4">
            <table class="table ">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded">
                <th>Timestamp</th>
                <th>Type</th>
                <th>Order No</th>
                <th>Due Date</th>
                <th>Price</th>
                <th>Total</th>
                <th>Status</th>
                <th>Receipt</th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="(data, index) in historyData"
                  :key="index"
                  class="text-center"
              >
                <td>{{ data.timestamp | timeStamp }}</td>
                <td>
                  {{ data.product_type }}
                </td>
                <td>
                  {{
                    data.order_seq_no
                        ? data.order_seq_no
                        : data.invoice_seq_no
                  }}
                </td>
                <td>{{ data.order_date | dateformat }}</td>
                <td>{{ Number(Math.abs(data.price)) | toCurrency }}</td>
                <td>{{ Number(Math.abs(data.total)) | toCurrency }}</td>
                <td>
                  {{ data.status }}
                </td>
                <td class="md-table-cell">
                  <div class="md-table-cell-container">
                    <v-btn
                        normal
                        small
                        @click="getOrderDetails(data.order_id)"
                    >{{
                        data.status == "Unpaid" ? "Invoice" : "Receipt"
                      }}</v-btn
                    >
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <v-pagination
              class="mt-2"
              v-model="historyPage"
              :length="historyTotalPages"
          ></v-pagination>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="
              () => {
                historyDialoge = false;
                closeEdit();
              }
            "
          >Close</v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="creditDialog" width="900">
      <v-card>
        <v-card-title class="headline"> Credit History </v-card-title>
        <v-card-text v-if="creditList && creditList.length > 0">
          <v-container>
            <v-row>
              <table class="history_table">
                <thead>
                <th>Timestamp</th>
                <th>Receipt ID</th>
                <th>Receipt Amount</th>
                <th>Type</th>
                <th>Service</th>
                <th>Payment Method</th>
                <th>Receipt</th>
                </thead>

                <tr
                    v-for="(credit, index) in creditList"
                    :key="index"
                    class="text-center"
                >
                  <td>{{ credit.timestamp | timeStamp }}</td>
                  <td>
                    {{ credit.order_seq_no }}
                  </td>
                  <td>
                    {{ credit.total }}
                  </td>
                  <td>{{ credit.product_type }}</td>
                  <td>{{ credit.service_name }}</td>
                  <td>
                    {{ credit.payment_method_name }}
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <v-btn
                          normal
                          small
                          v-if="credit.status_id != 2"
                          @click="getOrderDetails(credit.order_id)"
                      >Receipt</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text
            v-else
            style="height: 300px"
            class="pa-8 d-flex align-center justify-center"
        >
          <h3>No credit found!</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="creditDialog = false"
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="830" @input="closeEdit">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Edit Customer" style="color: black" >
                  </SvgIcon>
                  <v-btn  fab x-small class="shadow-0" @click="closeEdit">
                  <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row dense class="mt-6">
              <div class="col-md-3">
                <div style="height: 150px">
                  <image-uploader
                      @upload="
                      (data) => {
                        customerDetails.image = data;
                      }
                    "
                      @remove="
                      () => {
                        customerDetails.profile_image = null;
                      }
                    "
                      :image_path="customerDetails.profile_image"
                      text="Customer Picture "
                      messagePosition="inside"
                      messageText=""
                  ></image-uploader>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row row--dense">
                  <div class="col-md-6">
                    <label for="">First Name </label>
                    <v-text-field
                        v-model="customerDetails.first_name"
                        :rules="[maxLengthRule]"
                        class="q-text-field shadow-0" outlined dense hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <label for="">Last Name</label>
                    <v-text-field
                        v-model="customerDetails.last_name"
                        class="q-text-field shadow-0" outlined dense
                        hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <label for="">Mobile Number </label>


                    <mobile-number-field
                        v-model="customerDetails.mobile"
                        :refresh-country="refreshCountry"
                        required
                        :variant="1"
                        :dense="true" :outlined="true" />
                  </div>

                  <div class="col-md-6">
                    <label for="">Email </label>
                    <v-text-field
                        class="q-text-field shadow-0" outlined dense
                        validate-on-blur
                        hide-details="auto"
                        v-model="customerDetails.email"


                        :rules="[
                      (v) => !!v || 'Email is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </v-row>


            <v-row dense>
              <div class="col-md-4">
                <label for="">{{ customerAgeRange?'Age Group':'Date Of Birth' }}</label>
                <div  v-if="customerAgeRange">
                  <v-select
                     v-model="customerDetails.age_group"
                      :items="ageRanges"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true }"
                       outlined
                      dense
                      hide-details="auto"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                  ></v-select>
                </div>
                <div v-else>
                  <DateOfBirthField v-model="customerDetails.dob" :dense="true" :outlined="true" > </DateOfBirthField>
                </div>
                <!-- <date-of-birth-field v-model="customerDetails.dob"
                                     :dense="true" :outlined="true" > </date-of-birth-field> -->

              </div>

              <div class="col-md-4">
                <label for="">Nationality</label>
                <v-autocomplete
                    v-model="customerDetails.country_id"
                    :items="countries"
                    item-value="id"
                    item-text="name"
                    class="q-autocomplete shadow-0"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="#fff"
                    append-icon="mdi-chevron-down"
                >
                </v-autocomplete>
              </div>
              <div class="col-md-4">
                <label for="">Gender</label>
                <v-autocomplete
                    v-model="customerDetails.gender"
                    :items="['Male', 'Female']"
                    class="q-autocomplete shadow-0"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="#fff"
                ></v-autocomplete>
              </div>
            </v-row>


            <v-row dense>
              <div class="col-md-4">
                <label for="">Tags</label>
                <v-select
                    :items="tags"
                    outlined
                    dense
                    item-value="id"
                    item-text="name"
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="selectedTags"
                    required
                    background-color="#fff"
                    return-object
                    multiple
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                ></v-select>
              </div>

              <div class="col-md-4">
                <label for="">Height (CM)</label>
                <v-text-field
                    class="q-text-field shadow-0" outlined dense
                    hide-details="auto"
                    v-model="customerDetails.height"
                ></v-text-field>
              </div>
              <div class="col-md-4">
                <label for="">Weight (KG)</label>
                <v-text-field
                    class="q-text-field shadow-0" outlined dense
                    hide-details="auto"
                    v-model="customerDetails.weight"
                ></v-text-field>
              </div>
            </v-row>


            <v-row dense>
              <div class="col-md-4">
                <label for="">ID Type</label>
                <v-select
                    :rules="idTypeRule"
                    outlined
                    dense
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-value="id"
                    item-text="name"
                    :items="idProofTypes"
                    v-model="customerDetails.id_proof_type_id"
                    @change="changeIdProofTypeId"
                    validate-on-blur

                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                ></v-select>
              </div>

              <div class="col-md-4">
                <label for="">ID Number</label>
                <v-text-field
                    class="q-text-field shadow-0" outlined dense
                    hide-details="auto"

                ></v-text-field>
              </div>
              <div class="col-md-4">
                <label for="">ID Proof</label>
                <v-file-input
                    v-model="customerDetails.id_proof"
                    class="q-text-field shadow-0"
                    :placeholder="
                          `${
                            customerDetails.id_proof_path ? 'Change' : 'Select'
                          }`
                        "

                    :rules="idProofRule"
                    validate-on-blur

                    prepend-icon=""
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            color="cyan"
                            v-if="customerDetails.id_proof_path"
                            @click="openFile(customerDetails.id_proof_path)"
                            v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on">
                          mdi-attachment
                        </v-icon>
                      </template>
                      <span v-if="customerDetails.id_proof_path"
                      >Download uploaded file</span
                      >
                      <span v-else>Upload ID Proof</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index == 0"
                        color="cyan accent-4"
                        dark
                        label
                        small
                    >
                            <span style="width: 38px" class="text-truncate">{{
                                text
                              }}</span>
                    </v-chip>
                  </template>
                </v-file-input>
              </div>
            </v-row>
            <v-row dense>
              <div class="col-md-12 ">
                  <span class="d-flex align-center"><v-checkbox :ripple="false" v-model="customerDetails.opt_marketing"/> Opt In Marketing</span>
              </div>
            </v-row>
          </v-card-text>

          <v-card-actions >
            <card-reader-button
                className="text-blue text-capitalize light-blue-color"

                label="Samsotech Reader"
                docType="chip"

                @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
            ></card-reader-button>




            <card-data-button
                className="ml-4 text-blue text-capitalize light-blue-color"
                label="HID Omnikey"
                @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
            ></card-data-button>

            <v-spacer></v-spacer>
            <v-btn
                color=" "
                class="ma-2  "
                text
                @click="closeEdit"
            > Close </v-btn>
            <v-btn
                color=" darken-1"
                class="ma-2 white--text blue-color"
                @click="saveCustomer"
                text
            >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="scheduleDialog" width="70%">
      <v-card>
        <v-card-text>
          <div class="row pt-4">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Schedules" >
                </SvgIcon>

                <v-btn-toggle
                    borderless
                    class="date_button_navigation"
                    v-model="schedulesFilter.type"
                    mandatory
                    width="100%"
                    @change="getScheduleList"
                    dense
                >
                  <v-btn value="facility">Facility</v-btn>
                  <v-btn value="training">Training</v-btn>
                  <v-btn value="academy">Academy</v-btn>
                  <v-btn value="event">Events</v-btn>
                  <v-btn value="scanned">Membership</v-btn>
                </v-btn-toggle>

                <v-btn
                    class="export-button mt-2"
                    elevation="0"
                    height="36"
                    @click="exportSchedules"
                >
                  <SvgIcon text="Export" >
                    <template v-slot:icon>
                      <ExportIcon/>
                    </template>
                  </SvgIcon>
                </v-btn>
              </div>
            </div>
          </div>




          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive mt-4 ">
                <table class="table ">
                  <thead>
                  <tr class="opacity-70 tr-neon tr-rounded">
                    <th v-if="schedulesFilter.type == 'academy'">
                      Academy | Program
                    </th>
                    <th v-else-if="schedulesFilter.type == 'training'">
                      Package
                    </th>
                    <th v-else-if="schedulesFilter.type == 'event'">
                      Event Name
                    </th>
                    <th
                        @click="sortColumn('sort_order')"
                        v-if="schedulesFilter.type != 'scanned'"
                    >
                      Due Date
                      <v-icon class="ml-2" color="teal" small>
                        mdi-sort-{{
                          schedulesFilter.sort_order == "asc"
                              ? "ascending"
                              : "descending"
                        }}
                      </v-icon>
                    </th>
                    <th v-if="schedulesFilter.type != 'scanned'">Time</th>
                    <th v-if="schedulesFilter.type != 'scanned'">Facility</th>
                    <th
                        v-if="
                        schedulesFilter.type == 'academy' ||
                          schedulesFilter.type == 'training'
                      "
                    >
                      Trainer
                    </th>
                    <th v-if="schedulesFilter.type != 'scanned'">Service</th>
                    <th
                        v-if="
                        schedulesFilter.type == 'facility' ||
                          schedulesFilter.type == 'scanned'
                      "
                    >
                      Checkin Time
                    </th>
                    <th
                        v-if="
                        schedulesFilter.type == 'facility' ||
                          schedulesFilter.type == 'scanned'
                      "
                    >
                      Checkout Time
                    </th>
                    <th

                        v-if="schedulesFilter.type != 'scanned'"
                    >
                      <div class="text-left">Status</div>
                      <div class="search_column">
                        <v-select
                            @change="getScheduleList"
                            item-text="name"
                            item-value="id"
                            class="q-autocomplete shadow-0"
                            flat
                            hide-details
                            solo
                            v-model="schedulesFilter.status"
                            :items="getScheduleStatus()"
                            label="Status"
                        ></v-select>
                      </div>
                    </th>
                    <th v-if="schedulesFilter.type == 'scanned'">Scanned By</th>
                    <th v-if="schedulesFilter.type == 'scanned'">Membership</th>
                    <th v-if="schedulesFilter.type == 'scanned'">
                      Card Number
                    </th>
                    <th v-if="schedulesFilter.type != 'scanned'">Details</th>
                    <th v-if="schedulesFilter.type != 'scanned'">Action</th>
                  </tr>
                  </thead>

                  <tbody v-if="scheduleLog && scheduleLog.length > 0">
                  <tr
                      v-for="(schedule, index) in scheduleLog"
                      :key="index"
                      class="text-center"
                  >
                    <td v-if="schedulesFilter.type == 'academy'">
                      {{ schedule.workshop_name }} <br />
                      {{ schedule.program_name }}
                    </td>
                    <td v-else-if="schedulesFilter.type == 'training'">
                      {{ schedule.package_name }}
                    </td>
                    <td v-else-if="schedulesFilter.type == 'event'">
                      {{ schedule.event_name }}
                    </td>
                    <td v-if="schedulesFilter.type != 'scanned'">
                      {{ schedule.date | dateformat }}
                    </td>
                    <td v-if="schedulesFilter.type != 'scanned'">
                      {{ schedule.start_time | timeFormat }} to
                      {{ schedule.end_time | timeFormat }}
                    </td>
                    <td v-if="schedulesFilter.type != 'scanned'">
                      {{ schedule.facility_name }}
                    </td>
                    <td
                        v-if="
                          schedulesFilter.type == 'academy' ||
                            schedulesFilter.type == 'training'
                        "
                    >
                      {{ schedule.trainer_name || "NA" }}
                    </td>
                    <td v-if="schedulesFilter.type != 'scanned'">
                      {{ schedule.service }}
                    </td>
                    <td
                        v-if="
                          schedulesFilter.type == 'facility' ||
                            schedulesFilter.type == 'scanned'
                        "
                    >
                        <span v-if="schedule.check_in_time === 'N/A'">
                          N/A
                        </span>
                      <span v-else>
                          {{ schedule.check_in_time | timeStamp }}
                        </span>
                    </td>
                    <td
                        v-if="
                          schedulesFilter.type == 'facility' ||
                            schedulesFilter.type == 'scanned'
                        "
                    >
                        <span v-if="schedule.check_out_time === 'N/A'">
                          N/A
                        </span>
                      <span v-else>
                          {{ schedule.check_out_time | timeStamp }}
                        </span>
                    </td>

                    <td v-if="schedulesFilter.type != 'scanned'">
                      {{ schedule.status }}
                    </td>
                    <td v-if="schedulesFilter.type == 'scanned'">
                      {{ schedule.scanner_user_name }}
                    </td>
                    <td v-if="schedulesFilter.type == 'scanned'">
                      {{ schedule.membership_name }}
                    </td>
                    <td v-if="schedulesFilter.type == 'scanned'">
                      {{ schedule.ticket_name }}
                    </td>
                    <td v-if="schedulesFilter.type != 'scanned'">
                      <v-btn
                          v-if="![8, 13, 19].includes(schedule.order_status_id) && (schedule.is_open_dated != 1 || schedule.check_in_time != 'N/A')"
                          small
                          @click="gotoSchedule(index)"
                      >
                        View Schedule
                      </v-btn>
                    </td>
                    <td
                        v-if="
                          schedulesFilter.type != 'scanned' &&
                            schedule.status_id == 4 &&
                            schedule.order_status_id &&
                            (schedule.order_status_id == 11 ||
                              schedule.order_status_id == 12 ||
                              schedule.order_status_id == 18)
                        "
                    >
                      <v-btn small @click="refund(schedule)">Refund</v-btn>
                    </td>
                    <td v-else-if="schedulesFilter.type != 'scanned'">NA</td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td
                        :colspan="schedulesFilter.type == 'scanned' ? 5 : 9"
                        class="text-center"
                    >
                      No records
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
              <v-pagination
                  class="mt-2"
                  v-model="page"
                  :length="totalPages"
              ></v-pagination>
            </div>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="scheduleDialog = false"
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>

    <customer-product-wallet
        v-bind="productWalletModel"
        :customer_id="userID"
        @close="
        (productWalletModel.show = false),
          (productWalletModel.customer_id = null),
          getCustomerDetails()
      "
    ></customer-product-wallet>

    <customer-cash-wallet
        v-bind="cashWalletModel"
        :customer_id="userID"
        @close="
        (cashWalletModel.show = false),
          (cashWalletModel.customer_id = null),
          getCustomerDetails()
      "
    ></customer-cash-wallet>
    <CustomerQPointWallet
        v-bind="qPointWalletModel"
        :customerId="userID"
        @close="
          (qPointWalletModel.show = false),
          (qPointWalletModel.customer_id = null),
          (qPointWalletModel.points = 0),
          getCustomerDetails()
      "
    ></CustomerQPointWallet>

    <CustomerNotes
        :notes="notes"
        :is-customer="true"
        :notes-dialogue="notesDialogue"
        :customer-id="notesCustomerId"
        @closeDialogue="closeDialogue"
        @refreshData="refreshBookings"
    />

    <refund
        :repeatRefundAmount="repeatRefundAmount"
        :repeatBookingdata="repeatBookingdata"
        :workshopRefundAmount="workshopRefundAmount"
        :workshopCustomerAttendanceId="workshopCustomerAttendanceId"
        v-if="refund_dialog"
        :show="refund_dialog"
        @close="refund_dialog = false"
        @refunded="
        refund_dialog = false;
        getScheduleList();
      "
    ></refund>

    <order-details
        :id="orderId"
        @close="orderId = null"
        @paymentDone="orderAction"
    ></order-details>
  </div>
</template>

<script>
// import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import refund from "@/components/Order/Refund.vue";
import bookingFields from "@/mixins/bookingFieldValidation";
import CustomerProductWallet from "./CustomerProductWallet.vue";
import WalletIcon from '@/assets/images/misc/wallet-ico.svg';
import ProductWalletIcon from '@/assets/images/misc/wallet-01.svg';
import CreditWalletIcon from '@/assets/images/misc/wallet-02.svg';
import DeactivateBtnIcon from '@/assets/images/misc/profile-delete.svg';
import EditBtnIcon from '@/assets/images/misc/pencil-icon.svg';
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
import CustomerCashWallet from "@/views/Clients/Customer/CustomerCashWallet.vue";
import CustomerQPointWallet from "@/components/QPoints/CustomerQPointWallet.vue";
import CustomerNotes from "@/components/Customer/CustomerNotes.vue";

export default {
  components: {
    CustomerNotes,
    CustomerCashWallet,
    DateOfBirthField,
    MobileNumberField,
    ImageUploader,
    ExportIcon,
    SvgIcon,
    OrderDetails,
    refund,
    CustomerProductWallet,
    WalletIcon,
    ProductWalletIcon,
    CreditWalletIcon,
    DeactivateBtnIcon,
    EditBtnIcon,
    CustomerQPointWallet,
  },
  props: {
    userID: { type: Number, default: null },
    type: { type: String, default: "details" },
    isPermissionCheck: { type: Boolean, default: true },
  },
  mixins: [bookingFields],
  data() {
    return {
      isPanelOpened:false,
      expandedPanel:null,
      editGeneralNote:false,
      editCustomerAlert:false,
      editDeliveryAddress:false,
      walletColumn:3,

      historyPage: 1,
      historyTotalPages: 1,
      refund_dialog: false,
      workshopRefundAmount: null,
      workshopCustomerAttendanceId: null,
      repeatRefundAmount: null,
      repeatBookingdata: null,
      userModel: false,
      customerDetails: { trainers: [], memberships: [], opt_marketing: false },
      historyData: [],
      documentData: [],
      documentDialoge: false,
      historyType: null,
      membershipDialoge: false,
      ptDialoge: false,
      historyDialoge: false,
      image: null,
      menu: false,
      editDialog: false,
      valid: false,
      creditDialog: false,
      creditList: [],
      page: 1,
      totalPages: 1,
      orderId: null,
      scheduleLog: [],
      scheduleDialog: false,
      schedulesFilter: {
        sort_order2: "desc",
        sort_order: "asc",
        latest: true,
        status: null,
        type: "facility",
      },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      productWalletModel: {
        show: false,
        customer_id: null,
      },
      cashWalletModel: {
        show: false,
        customer_id: null,
      },
      qPointWalletModel: {
        show: false,
        customer_id: null,
        points: 0,
      },
      refreshCountry: false,
      customerNote: null,
      customerAlertNote: null,
      customerDeliveryAddress: null,

      selectedTags: [],


      notesDialogue: false,
      notesBookingId:null,
      notesCustomerId:null,
      notes:[],
    };
  },
  watch: {
    userID(val) {
      if (val != null) {
        if (this.type == "history") {
          this.customerHistory();
        } else {
          this.getCustomerDetails();
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    page: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.creditDialog) {
            this.getCreditList();
          } else if (this.historyDialoge) {
            this.customerHistory();
          } else if (this.scheduleDialog) {
            this.getScheduleList();
          }
        }
      },
    },
    historyPage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.customerHistory();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    if (this.$store.getters.getCustomerAgeRangeConfiguration.status == false) {
      this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
    }
    if (this.$store.getters.getCustomerAgeRange.status == false) {
      this.$store.dispatch("LoadCustomerAgeRange");
    }
  },
  computed: {
    maxLengthRule() {
      return (v) => (v && v.length <= 50) || 'Maximum 50 characters allowed'
    },
    activeMembership() {
      let data = this.customerDetails.memberships.filter(
          (item) => item.status == 1
      );
      if (data.length == 0 && this.customerDetails.memberships.length > 0) {
        return [
          this.customerDetails.memberships[
          this.customerDetails.memberships.length - 1
              ],
        ];
      }
      return [];
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
  },
  methods: {
    closeDialogue(){
      this.notesDialogue= false;
      this.notes=[];
    },
    refreshBookings(){
      this.getNotes(this.notesBookingId)
    },
    async getNotes(customer_id){
      this.showLoader('Loading notes')
      await this.$http.get(`venues/customers/notes/${customer_id}`).then((response) => {
        if (response.status === 200 && response.data.status) {
          this.notes = response.data.data;
        }else{
          this.notes = [];
        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(()=>{
        this.hideLoader()
      });
    },
    async openNotes(customer){
      await this.getNotes(customer.id);
      this.notesBookingId = customer.id;
      this.notesCustomerId = customer.id;
      this.notesDialogue = true;
    },
    expansionPannelChanged(){
      console.log(this.expandedPanel)
    },

    editCustomerField(field){
      if(field==='general_note'){
        this.editGeneralNote  = !this.editGeneralNote;
      }if(field==='delivery_address'){
        this.editDeliveryAddress  = !this.editDeliveryAddress;
      }if(field==='customer_alert'){
        this.editCustomerAlert  = !this.editCustomerAlert;
      }
    },
    manageCashWallet(){
      this.cashWalletModel.customer_id = this.UserID;
      this.cashWalletModel.show = true;
    },
    getProductWallet() {
      this.productWalletModel.customer_id = this.UserID;
      this.productWalletModel.show = true;
    },
    manageQPointWallet(){
      this.qPointWalletModel.customer_id = this.UserID;
      this.qPointWalletModel.points = this.customerDetails.points;
      this.qPointWalletModel.show = true;
    },
    getCustomerDetails() {
      if (this.userID && this.userID !== null) {
        this.showLoader("LOADING ...");
        this.$http
            .get("venues/customers/" + this.userID)
            .then((response) => {
              if (response.status == 200 && response.data.status) {
                this.hideLoader();
                this.customerDetails = response.data.data;
                this.walletColumn = this.customerDetails.points > 0?3:4;
                // console.log(this.customerDetails);
                this.customerNote = response.data.data
                    ? response.data.data.notes
                    : null;
                this.customerAlertNote = response.data.data
                    ? response.data.data.alert_notes
                    : null;
                this.customerDeliveryAddress = response.data.data
                    ? response.data.data.delivery_address
                    : null;
                if (response.data.data.opt_marketing == 1) {
                  this.customerDetails.opt_marketing = true;
                } else {
                  this.customerDetails.opt_marketing = false;
                }
                if (this.type == "edit") {
                  // console.log("edit");
                  this.showEdit();
                  if (this.customerDetails.customer_documents) {
                    if (
                        this.customerDetails.customer_documents[0] &&
                        this.customerDetails.customer_documents[0].id_proof_type_id
                    ) {
                      this.$set(
                          this.customerDetails,
                          "id_proof_type_id",
                          this.customerDetails.customer_documents[0]
                              .id_proof_type_id
                      );
                    }
                    if (
                        this.customerDetails.customer_documents[0] &&
                        this.customerDetails.customer_documents[0].id_proof_number
                    ) {
                      this.$set(
                          this.customerDetails,
                          "id_proof_number",
                          this.customerDetails.customer_documents[0].id_proof_number
                      );
                    }
                    if (
                        this.customerDetails.customer_documents[0] &&
                        this.customerDetails.customer_documents[0].id_proof_path
                    ) {
                      this.$set(
                          this.customerDetails,
                          "id_proof_path",
                          this.customerDetails.customer_documents[0].id_proof_path
                      );
                    }
                  }
                  if (this.customerDetails.venue_customer_tags) {
                    this.selectedTags = [];
                    this.customerDetails.venue_customer_tags.forEach((item) => {
                      this.selectedTags.push({
                        id: item.tag.id,
                        name: item.tag.name,
                      });
                    });
                  } else {
                    this.selectedTags = [];
                  }
                } else {
                  // console.log("not edit");
                  this.userModel = true;
                  this.refreshCountry = !this.refreshCountry;
                  if (this.customerDetails.venue_customer_tags) {
                    this.selectedTags = [];
                    this.customerDetails.venue_customer_tags.forEach((item) => {
                      this.selectedTags.push({
                        id: item.tag.id,
                        name: item.tag.name,
                      });
                    });
                  } else {
                    this.selectedTags = [];
                  }
                }
              } else {
                this.hideLoader();
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      }
    },
    orderAction() {
      this.$emit("orderAction");
      this.$emit("confirm");
      this.closeAll();
    },
    closeAll() {
      this.historyDialoge = false;
      this.userModel = false;
      this.membershipDialoge = false;
      this.ptDialoge = false;
      this.historyDialoge = false;
      this.editDialog = false;
      this.valid = false;
      this.creditDialog = false;
      this.page = 1;
      this.totalPages = 1;

      this.orderId = null;
      this.scheduleLog = [];
      this.scheduleDialog = false;
      this.schedulesFilter = {
        sort_order2: "desc",
        sort_order: "asc",
        latest: true,
        status: null,
      };
      this.$emit("close");
    },
    getDocuments() {
      if (this.userID === null || this.userID == null) {
        return false;
      }
      this.showLoader("LOADING DOCUMENTS...");
      this.$http
          .get("venues/customers/get-documents/" + this.userID)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              let data = response.data.data;
              this.documentDialoge = true;
              let documentsList = [];
              //console.log(data);
              data.customer.forEach((item) => {
                documentsList.push({
                  name: "ID Proof",
                  link: item.id_proof_path,
                  type: "Customer Document",
                });
              });
              data.facility_booking.forEach((item) => {
                documentsList.push({
                  name: "Facility ID Proof",
                  link: item.id_proof,
                  type: "Facility Booking Document",
                });
              });

              data.member.forEach((item) => {
                if (item.documents && item.documents.length > 0) {
                  item.documents.forEach((memberDoc) => {
                    documentsList.push({
                      name: memberDoc.felid_name,
                      link: memberDoc.felid_path
                          ? memberDoc.felid_path
                          : memberDoc.file_path,
                      type: "Membership Document",
                    });
                  });
                }
                if (item.document) {
                  documentsList.push({
                    name: item.document.original_file_name,
                    link: item.document.file_path,
                    type: "Membership ID Proof Document",
                  });
                }
              });

              data.workshop.forEach((item) => {
                if (item.document) {
                  documentsList.push({
                    name: item.document.document_type.name,
                    link: item.document.file_path,
                    type: "Academy Document",
                  });
                }
              });
              this.documentData = documentsList;
            } else {
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    customerHistory() {
      if (this.userID === null || this.userID == null) {
        return false;
      }
      var params = "";
      if (this.historyType) {
        params =
            "?product_type_id=" + this.historyType + "&page=" + this.historyPage;
      } else {
        params = "?page=" + this.historyPage;
      }
      this.historyData = [];
      this.showLoader("LOADING ...");
      this.$http
          .get("venues/customers/history/" + this.userID + params)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.historyData = response.data.data;
              this.historyDialoge = true;
            } else {
              this.hideLoader();
            }
            this.historyTotalPages = response.data.total_pages;
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getOrderDetails(id) {
      this.orderId = id;
    },
    allHistory() {
      this.historyType = null;
      this.historyLog();
    },
    getPersonalTrainingHistory() {
      this.historyType = "PERSONALTRAINING";
      this.historyLog();
    },
    closeEdit() {
      this.editDialog = false;
      if (this.type == "details") {
        this.userModel = true;
        this.getCustomerDetails();
      } else if (this.type == "history") {
        this.$emit("close");
      } else {
        this.image = null;
        this.$emit("close");
        this.$refs.form.resetValidation();
      }
    },
    saveCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.customerDetails) {
        if (this.customerDetails[key]) {
          formData.append(key, this.customerDetails[key]);
        }
      }
      if (
          !this.customerDetails["last_name"] ||
          this.customerDetails["last_name"] == null
      ) {
        formData.append("last_name", "");
      }
      formData.append("opt_marketing", this.customerDetails.opt_marketing);
      if (this.selectedTags) {
        this.selectedTags.forEach((tag, key) => {
          formData.append(`customer_tag[${key}][id]`, tag.id);
          formData.append(`customer_tag[${key}][name]`, tag.name);
        });
        // formData.append("tag_ids", this.selectedTags);
      }

      this.userID = this.customerDetails.id;
      this.$http({
        method: "post",
        data: formData,
        url: "venues/customers/" + this.customerDetails.id,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.editDialog = false;
              this.showSuccess("Customer Details Updated.");
              this.closeEdit();
              this.$emit("updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showEdit() {
      if (this.$store.getters.getCountries.status == false) {
        this.$store.dispatch("loadCountries");
      }

      if (this.$store.getters.getTags.status == false) {
        this.$store.dispatch("loadTags");
      }

      if (this.customerDetails.image) {
        this.image = this.s3BucketURL + this.customerDetails.image;
      }
      if (this.type == "details") {
        this.userModel = false;
      }
      this.editDialog = true;
    },
    getCreditList() {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      this.$http
          .get(
              `venues/sales?status_id=4&order_by=created_at&order_by_value=DESC&customer_id=${userId}&credit`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.creditList = response.data.data;
              this.creditDialog = true;
              this.totalPages = response.data.total_pages;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    backToCreditList() {
      this.creditDialog = true;
    },
    sortColumn(column) {
      this.schedulesFilter.latest = false;
      this.schedulesFilter[column] =
          this.schedulesFilter[column] == "desc" ? "asc" : "desc";
      this.getScheduleList();
    },
    getScheduleList(param = null) {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      let url = `venues/customers/schedules/${userId}?page=${this.page}&sort_order=${this.schedulesFilter.sort_order}`;
      if (this.schedulesFilter.latest) {
        url += "&latest=true";
      }
      if (this.schedulesFilter.status) {
        url += `&status=${this.schedulesFilter.status}`;
      }
      let type = this.schedulesFilter.type
          ? this.schedulesFilter.type
          : "facility";
      url += `&type=${type}`;
      this.showLoader("Loading");
      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.scheduleLog = response.data.data;
              if (param == "schedule_history") {
                this.scheduleDialog = true;
              }
              this.totalPages = response.data.total_pages;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    gotoSchedule(index) {
      let data = this.scheduleLog[index];
      let venue_service = this.venueServices.find(
          (x) => x.venue_service_id == data.venue_service_id
      );
      if (this.schedulesFilter.type == "facility") {
        if (this.$route.name != "ScheduleWithParams") {
          this.$router.push({
            name: "ScheduleWithParams",
            params: {
              data: btoa(
                  JSON.stringify({
                    venue_service: venue_service,
                    date: data.date,
                    order_id: data.order_id,
                  })
              ),
            },
          });
        }
      } else if (this.schedulesFilter.type == "event") {
        if (this.$route.name != "EventsView") {
          this.$router.push({
            name: "EventsView",
            params: { data: btoa(data.event_id) },
          });
        }
      } else if (this.schedulesFilter.type == "training") {
        if (this.$route.name != "TrainerCustomers") {
          this.$router.push({
            name: "TrainerCustomers",
            params: { data: btoa(data.trainer_id) },
          });
        } else {
          this.scheduleDialog = false;
          this.userModel = false;
          this.$emit("close");
        }
      } else if (this.schedulesFilter.type == "academy") {
        if (this.$route.name != "WorkshopView") {
          this.$router.push({
            name: "WorkshopView",
            params: {
              data: btoa(
                  JSON.stringify({
                    id: data.workshop_id,
                    date: data.date,
                    workshop_schedule_id: data.workshop_schedule_id,
                    workshop_program_id: data.workshop_program_id,
                  })
              ),
            },
          });
        }
        if (this.$route.name == "WorkshopView") {
          this.$router.go(
              this.$router.push({
                name: "WorkshopView",
                params: {
                  data: btoa(
                      JSON.stringify({
                        id: data.workshop_id,
                        date: data.date,
                        workshop_schedule_id: data.workshop_schedule_id,
                        workshop_program_id: data.workshop_program_id,
                      })
                  ),
                },
              })
          );
        }
      }
    },
    exportSchedules() {
      let userId = this.userID == null ? this.customerDetails.id : this.userID;
      let url = `venues/customers/schedules/export/${userId}?page=${this.page}&sort_order=${this.schedulesFilter.sort_order}`;
      if (this.schedulesFilter.latest) {
        url += "&latest=true";
      }
      if (this.schedulesFilter.status) {
        url += `&status=${this.schedulesFilter.status}`;
      }
      let type = this.schedulesFilter.type
          ? this.schedulesFilter.type
          : "facility";
      url += `&type=${type}`;
      this.showLoader("Downloading..");
      this.$http
          .get(url, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.downloadFile(
                  response,
                  `${this.customerDetails.first_name} ${this.customerDetails
                      .last_name || ""} Schedules`,
                  "pdf"
              );
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    refund(schedule) {
      this.confirmModel = {
        id: schedule.id,
        title: `Do you want refund this schedule?`,
        description: `This will refund selected schedule. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: this.schedulesFilter.type,
        data: schedule,
      };
    },

    confirmActions(data) {
      this.payments = [
        {
          card_type_id: null,
          payment_code: null,
          payment_method_id: null,
          amount: null,
          payment_method: null,
        },
      ];

      this.$store.commit("setOrderPayments", this.payments);

      if (data.type == "academy") {
        if (data.id && data.data) {
          this.workshopCustomerAttendanceId = data.id;
          this.showLoader("Loading...");

          this.$http
              .get(
                  `venues/workshops/schedules/booking/single-class-refund-amount/${data.data.order_id}`
              )
              .then((response) => {
                if (response.status == 200 && response.data.status) {
                  this.hideLoader();
                  if (response.data.type == "partial") {
                    this.workshopRefundAmount = response.data.refund_amount;
                    this.refund_dialog = true;
                  } else if (response.data.type == "normal") {
                    this.$store
                        .dispatch("loadOrderDetails", data.data.order_id)
                        .then((response) => {
                          if (response.status == 200) {
                            this.refund_dialog = true;
                          }
                        });
                  }
                }
              })
              .catch((error) => {
                this.customersLogDialog = false;
                this.errorChecker(error);
              });
        }
      } else if (data.type == "facility") {
        if (data.data.facility_booking_repeat_id) {
          var obj = {
            booking_id: data.data.id,
            date: data.data.date,
            start_time: data.data.start_time,
            end_time: data.data.end_time,
            order_id: data.data.order_id,
            facility_id: data.data.facility_id,
            status_id: data.data.facility_status_id,
          };
          this.showLoader();
          this.$http
              .post(`venues/facilities/bookings/repeat/check-refund-amount`, {
                booking_ids: [obj],
              })
              .then((response) => {
                if (response.status == 200 && response.data.status == true) {
                  this.hideLoader();
                  if (response.data.total_amount) {
                    this.repeatRefundAmount = response.data.total_amount;
                    this.repeatBookingdata = [obj];
                    this.refund_dialog = true;
                  }
                }
              })
              .catch((error) => {
                this.errorChecker(error);
              });
        } else {
          this.$store
              .dispatch("loadOrderDetails", data.data.order_id)
              .then((response) => {
                if (response.status == 200) {
                  this.refund_dialog = true;
                }
              });
        }
      } else if (data.type == "training") {
        this.showLoader("Loading...");
        var formData = new FormData();
        formData.append("training_session_id", data.data.id);
        this.$http
            .post(`venues/trainers/customers/sessions/refund`, formData)
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                this.getScheduleList();
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      } else if (this.schedulesFilter.type == "event") {
        this.$store
            .dispatch("loadOrderDetails", data.data.order_id)
            .then((response) => {
              if (response.status == 200) {
                this.refund_dialog = true;
              }
            });
      } else if (data.type == "Deactivate" || data.type == "Activate") {
        this.removeCustomer(data);
      }
      this.confirmModel.id = null;
    },

    removeCustomer(data) {
      this.showLoader();
      this.$http
          .delete(`venues/customers/${this.customerDetails.id}`)
          .then((res) => {
            if (res.status == 200) {
              this.showSuccess(`Customer ${data.type} Successfully.`);
              this.hideLoader();
              this.getCustomerDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    getScheduleStatus() {
      if (this.schedulesFilter.type == "facility") {
        return [
          { id: null, name: "All" },
          { id: 11, name: "Pending" },
          { id: 12, name: "Completed" },
          { id: 13, name: "Cancelled" },
          { id: 19, name: "Partial Cancel" },
          { id: 18, name: "Partial Refund" },
        ];
      } else if (this.schedulesFilter.type == "event") {
        return [
          { id: null, name: "All" },
          { id: 11, name: "Pending" },
          { id: 12, name: "Completed" },
        ];
      } else if (
          this.schedulesFilter.type == "academy" ||
          this.schedulesFilter.type == "training"
      ) {
        return [
          { id: null, name: "All" },
          { id: 1, name: "Attended" },
          { id: 2, name: "No Show" },
          { id: 0, name: "NA" },
        ];
      }
    },

    updateNote() {
      //if (this.customerNote || this.customerAlertNote) {
      let data = {
        notes: this.customerNote,
        alert_note: this.customerAlertNote,
        delivery_address: this.customerDeliveryAddress
      };
      this.showLoader();
      this.$http
          .post("venues/customers/note/" + this.customerDetails.id, data)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Customer notes updated!");
              this.editGeneralNote = false;
              this.editCustomerAlert = false;
              this.editDeliveryAddress = false;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      //}
    },
    clearAlertNote() {
      if (this.customerAlertNote) {
        this.showLoader();
        this.$http
            .post("venues/customers/clear-alert-note/" + this.customerDetails.id)
            .then((response) => {
              this.hideLoader();
              if (response.status == 200) {
                this.customerAlertNote = null;
                this.showSuccess("Customer alert note cleared!");
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },

    deactivateConfirmModal() {
      let type =
          this.customerDetails.status_id == 1 ? "Deactivate" : "Activate";
      this.confirmModel = {
        id: this.customerDetails.id,
        title: `Do you want to ${type} this customer?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    setCardData(data) {
      if (data.id_proof_type_id) {
        this.$set(
            this.customerDetails,
            "id_proof_type_id",
            data.id_proof_type_id
        );
      }
      if (data.id_proof_number) {
        this.$set(
            this.customerDetails,
            "id_proof_number",
            data.id_proof_number
        );
      }
      if (data.id_proof_path) {
        this.$set(this.customerDetails, "id_proof_path", data.id_proof_path);
      }
      if (data.id_proof) {
        this.$set(this.customerDetails, "id_proof", data.id_proof);
      }
      if (data.dob) {
        this.$set(this.customerDetails, "dob", data.dob);
      }
      if (data.gender) {
        this.$set(this.customerDetails, "gender", data.gender);
      }
      if (data.country_id) {
        this.$set(this.customerDetails, "country_id", data.country_id);
      }
    },
    changeIdProofTypeId() {
      if (
          this.customerDetails.customer_documents &&
          this.customerDetails.customer_documents.length
      ) {
        let objType = this.customerDetails.customer_documents.find((x) => {
          return x.id_proof_type_id === this.customerDetails.id_proof_type_id;
        });
        if (typeof objType !== "undefined" && objType.id_proof_type_id) {
          this.customerDetails.id_proof_number = objType.id_proof_number;
          this.customerDetails.id_proof_path = objType.id_proof_path;
        } else {
          this.customerDetails.id_proof_number = null;
          this.customerDetails.id_proof_path = null;
        }
      } else {
        console.log("document length 0");
      }
    },
  },
};
</script>

<style scoped>
.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}

.credit_table table thead tr th {
  background: #066a8c !important;
  color: #fff !important;
}
.history_table thead {
  background-color: #ccc;
}
.history_table thead th {
  padding: 5px;
}
</style>
