<template>
  <v-container class="mx-auto" fluid>
    <v-row>
      <v-col md="8">
        <h4 class="text-base font-semibold text-black m-b-4">
          About Event
        </h4>
        <p class="text-sm text-light-black" >
          <template v-if="event.description">
          {{ event.description }}
          </template>
          <template v-else>
            No Description
          </template>
        </p>
      </v-col>
      <v-col md="4">
        <h4 class="text-base font-semibold m-b-4 text-black">
          Time and Location
        </h4>
        <div class="rounded-lg p-3 bordered bg-white">
          <div
              v-for="(eTiming, index) in event.event_schedules"
              :key="index"
              :class="{'border-bottom':index < event.event_schedules.length -1}"
              class="d-flex align-start gap-x-2 mb-2 pb-2"
          >
            <LocationIcon style="min-width: fit-content" class="mt-2"/>
            <div>
              <h5 class="text-sm font-medium" style="color: black">
                {{ eTiming.facility_name ? eTiming.facility_name : eTiming.location }}
              </h5>
              <p class="mb-1 text-sm text-light-black">
                {{ eTiming.start_date | dateformat }} - {{ eTiming.end_date | dateformat }} |
                {{ eTiming.start_time | timeFormat }} - {{ eTiming.end_time | timeFormat }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card class="shadow p-x-8 p-y-6 rounded-5 m-t-8">
      <SvgIcon class="gap-x-2 text-base font-semibold m-b-4" text="Ticket Details">
        <template v-slot:icon>
          <TicketIcon/>
        </template>
      </SvgIcon>

      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr class="rounded-lg tr-rounded tr-neon opacity-70">
            <th class="font-normal text-black">Ticket</th>
            <th class="font-normal text-black">Price ({{ currency }})</th>
            <th class="font-normal text-black">Attendance</th>
            <th class="font-normal text-black"  v-if="checkReadPermission($modules.events.sales.slug)">Sales ({{ currency }})</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="data in event.tickets" :key="data.id">
            <td>
              {{ data.name }}
            </td>
            <td>
              {{ Number(data.total_amount) }}
            </td>
            <td>
              <v-progress-linear
                  :value="((data.participants || 0)/(data.quantity || 1)*100)"
                  background-color="rgba(234, 233, 233, 1)"
                  class="rounded-xl text-white"
                  color="#42D4A8"
                  height="25"
              >
                {{ data.participants || 0 }} / {{ data.quantity || 0 }}
              </v-progress-linear>
            </td>
            <td v-if="checkReadPermission($modules.events.sales.slug)">
              {{ data.sales }}
            </td>
          </tr>
          <tr>
            <th>Total</th>
            <th>
              {{
                event.tickets.reduce(
                    (a, b) => a + parseFloat(b.total_amount),
                    0
                )
              }}
            </th>
            <th>
              <v-progress-linear
                  :value="((event.participants || 0)/(event.total_tickets || 1)*100)"
                  background-color="rgba(234, 233, 233, 1)"
                  class="rounded-xl text-white"
                  color="#42D4A8"
                  height="25"
              >
                {{ event.participants || 0 }} / {{ event.total_tickets || 0 }}
              </v-progress-linear>
            </th>
            <th v-if="checkReadPermission($modules.events.sales.slug)">
              {{ event.sales }}
            </th>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <div v-if="event.lineups.length > 0" class="m-t-8">
      <h3 class="text-base font-semibold">Lineups</h3>
      <div style="margin-top: -1rem;">
        <div class="d-flex justify-space-between">
          <div class="carousel-icon left-icon pointer shadow" @click="carousel('left')">
            <SvgIcon>
              <template v-slot:icon>
                <LeftIcon/>
              </template>
            </SvgIcon>
          </div>
          <div class="carousel-icon right-icon pointer shadow" @click="carousel('right')">
            <SvgIcon>
              <template v-slot:icon>
                <RightIcon/>
              </template>
            </SvgIcon>
          </div>
        </div>
        <v-row>
          <v-col v-for="lineup in event.lineups.slice(0,3)" :key="lineup.id" md="4">
            <EventLineup :lineup="lineup"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="event.documents.length > 0" class="m-t-8">
      <h3 class="text-base font-semibold">Documents</h3>
      <div class="d-flex flex-wrap gap-x-4 gap-y-4 p-y-4">
        <a
            v-for="document in event.documents"
            :key="document.event_document_id"
            :href="s3BucketURL+document.file_path"
            target="_blank"
        >
          <SvgIcon :text="document.name" class="text-light-black gap-x-2 text-capitalize">
            <template v-slot:icon>
              <DocumentIcon/>
            </template>
          </SvgIcon>
        </a>
      </div>
    </div>

    <v-card v-if="event.seat_map_id" class="mt-6">
      <v-card-title>Seat Map</v-card-title>
      <v-card-text>
        <EventTicketSeatMap :event="event"/>
      </v-card-text>
    </v-card>
  </v-container>

</template>

<script>
import EventTicketSeatMap from './EventTicketSeatMap.vue';
import LocationIcon from '@/assets/images/events/location.svg';
import TicketIcon from '@/assets/images/misc/ticket-icon.svg';
import SvgIcon from "@/components/Image/SvgIcon.vue";
import EventLineup from "@/components/Event/EventLineup.vue";
import LeftIcon from '@/assets/images/events/left-icon.svg'
import RightIcon from '@/assets/images/events/right-icon.svg'
import DocumentIcon from '@/assets/images/misc/document-icon.svg'

export default {
  props: {
    event: Object,
  },
  components: {
    EventLineup,
    SvgIcon,
    EventTicketSeatMap,
    LocationIcon,
    TicketIcon,
    LeftIcon,
    RightIcon,
    DocumentIcon,
  },
  computed: {
    currency() {
      return this.$store.getters.currencyCode;
    }
  },
  methods: {
    carousel(direction) {
      if (direction === "left") {
        this.event.lineups.unshift(this.event.lineups.pop());
      } else {
        this.event.lineups.push(this.event.lineups.shift());
      }
      this.$forceUpdate()
    },
    downloadFile(path) {
      if (
          !this.$store.getters.checkExportPermission(
              this.$modules.event.management.id
          )
      ) {
        return;
      }
      window.open(path, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-icon {
  position: relative;
  top: 150px;
  display: unset !important;
  border-radius: 29px;
  height: 32px;
}
</style>
